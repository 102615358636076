@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900);
.drawer-margin {
    margin-top: 65px;
}
.drawer-margin {
  margin-top: 65px;
}
.DateRangePicker
  .DateRangePicker__Month
  .DateRangePicker__MonthDates
  .DateRangePicker__Week
  .DateRangePicker__Date
  .DateRangePicker__CalendarSelection {
  background-color: #019ee1;
  border: #019ee1;
}
.DateRangePicker
  .DateRangePicker__Month
  .DateRangePicker__MonthDates
  .DateRangePicker__Week
  .DateRangePicker__Date
  .DateRangePicker__CalendarSelection--is-pending {
  background-color: #019ee1;
}
.DateRangePicker
  .DateRangePicker__Month
  .DateRangePicker__MonthDates
  .DateRangePicker__Week
  .DateRangePicker__Date
  .DateRangePicker__CalendarHighlight--single {
  background-color: #fff;
  border: 1px solid #019ee1;
}
.DateRangePicker
  .DateRangePicker__Month
  .DateRangePicker__MonthDates
  .DateRangePicker__Week
  .DateRangePicker__Date
  .DateRangePicker__CalendarSelection--is-pending {
  background-color: rgb(38, 155, 209);
}

.inputmr {
  margin-right: 10px;
}

.custom-chip {
  margin-right: 5px;
  background: whitesmoke;
}

.custom-chip:hover {
  background: gray;
}

.column-style {
  font-size: 10px;
  padding: 0.2rem !important;
  text-align: center;
}
.loading-blue .md-circular-progress-path {
  stroke: #3f51b5 !important;
}

.header-style {
  padding: 0.2rem !important;
  text-align: center;
  width: 80s;
}

.boldText {
  font-weight: bold;
}

.mousePointer {
  cursor: pointer;
}

.tableHeight {
  max-height: 300px;
}

.status-text-style {
  display: inline;
  vertical-align: super;
}

/* .fix-menu {
  position: relative !important;
}

.fix-menu ul {
  position: relative;
 
}
.fix-menu .md-list-tile {
  height: 30px !important;
} */
.dialog-fix .md-cell--2-offset,
.dialog-fix .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
  margin-left: 0px !important;
}

.dialog-fix .md-cell--8,
.dialog-fix .md-cell--8-desktop.md-cell--8-desktop {
  width: 100% !important;
}

.footertTable .md-table-pagination {
  margin-left: 660px !important;
}

/*
	* Template name: Authfy - Responsive Login and Signup Page Template
	* Template URI: http://koder.top/demo/authfy/
	* Version: 1.0	
	* Author: Farhadur Rahim
	* Author URI: https://themeforest.net/user/webmechanicx
*/

/* Table of Content
==================================================
	#01. Web Fonts
	#02. Common CSS
	#03. Section Panel UI CSS
    #04. Check box UI CSS
    #05. Responsive CSS
*/

/*--------------------*/
/* Import all style */
/*--------------------*/

/* @import 'bootstrap.min.css'; */
/* @import 'font-awesome.min.css'; */
/* @import 'preloader.css'; */

/*--------------------*/
/* 01. Web Fonts */
/*--------------------*/

/*--------------------*/
/* 02. Common CSS */
/*--------------------*/

/* body,
html {
  background-color: #f0f2f5;
  background-image: url('../images/computer-1867758_1920-min.jpg');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

body {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  color: #44525f;
} */

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #2d385e;
}

a:focus {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Titillium Web', sans-serif;
  color: #2d385e;
}

a,
a:hover,
a:focus {
  color: #4f77ff;
}

.btn-primary {
  background-color: #4f77ff;
  border-color: #4f77ff;
}

.btn-primary:hover,
.btn-primary:active:hover,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #486ff2;
  border-color: #486ff2;
}

.authfy-login .btn.btn-lg {
  border-radius: 3px;
  box-shadow: 0px 2px 3px #9c9c9c;
}

.brand-logo {
  margin-top: 0px;
  margin-bottom: 35px;
}

/*--------------------*/
/* 03. Section Panel UI CSS */
/*--------------------*/

.authfy-container {
  margin-top: 97px;
  margin-bottom: 97px;
}

.authfy-panel-left {
  background-color: rgb(92, 156, 220, 0.92);
  /* background-color: rgb(4, 156, 220, 0.92); */
  /* background-color: rgba(38, 43, 72, 0.92); */
  color: #ffffff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.authfy-panel-right {
  background-color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.authfy-login,
.authfy-panel-left .brand-col {
  height: 460px;
}
.authfy-panel-left .brand-col {
  display: table;
  width: 100%;
  padding: 25px;
}

.authfy-panel-left .brand-col .headline {
  display: table-cell;
  vertical-align: middle;
}

.authfy-panel-left .brand-col .headline h1,
.authfy-panel-left .brand-col .headline h2,
.authfy-panel-left .brand-col .headline h3 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

.authfy-login {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.authfy-panel {
  padding: 20px 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.authfy-panel .authfy-heading {
  margin-bottom: 30px;
}

.authfy-login .auth-title {
  font-weight: 700;
}

.authfy-login .authfy-panel.active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.brand-col .social-buttons a {
  color: #ffffff;
  margin-top: 15px;
}

.brand-col .social-buttons a:hover {
  color: white;
  opacity: 0.9;
}

.brand-col .social-buttons .btn-facebook {
  background: #3b5998;
}
.brand-col .social-buttons .btn-twitter {
  background: #00aced;
}
.brand-col .social-buttons .btn-google {
  background: #c32f10;
}

.brand-col .social-buttons a > span {
  padding-left: 5px;
}

.panel-login {
  margin-top: 20px;
}

.panel-login .remember-row {
  margin-bottom: 10px;
}

.panel-login .remember-row label {
  font-weight: normal;
  position: relative;
  cursor: pointer;
  color: #666;
  padding-left: 24px;
}

.authfy-login .forgotPwd {
  text-align: right;
  margin-top: 10px;
}

.panel-login .form-control,
.panel-signup .form-control,
.panel-forgot .form-control {
  color: #333333;
  font-size: 16px;
  height: 50px;
  padding: 12px 0px;
  outline: none;
  border: 0 none;
  box-shadow: none;
}

.panel-login .form-control:focus + .focus-input:before,
.panel-signup .form-control:focus + .focus-input:before,
.panel-forgot .form-control:focus + .focus-input:before {
  width: 100%;
}

.wrap-input {
  width: 100%;
  position: relative;
}

.focus-input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input:before {
  background-color: #4f77ff;
  content: '';
  width: 0;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.authfy-panel.panel-forgot {
  padding-top: 40px;
}

.pwdMask {
  position: relative;
}

.pwdMask .pwd-toggle {
  color: #cccccc;
  position: absolute;
  z-index: 2;
}

.panel-login .pwdMask .pwd-toggle,
.panel-signup .pwdMask .pwd-toggle {
  top: 15px;
  right: 15px;
}

.pwdMask .pwd-toggle:hover,
.pwdMask .pwd-toggle:focus {
  cursor: pointer;
}

.pwdMask .pwd-toggle:hover,
.pwdMask .pwd-toggle:focus,
.pwdMask .pwd-toggle.fa-eye {
  color: #4f77ff;
}

.panel-signup .term-policy a {
  text-decoration: underline;
}

/*--------------------*/
/* 04. Check box UI CSS */
/*--------------------*/

.checkbox input[type='checkbox'] {
  position: absolute;
  right: 9000px;
}

.checkbox input[type='checkbox'] + .label-text:before,
.checkbox input[type='checkbox'] + .label-text:after {
  font-size: 11px;
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 2px;
  margin-left: 0;
  position: absolute;
  top: 2px;
  left: 0;
}

.checkbox input[type='checkbox'] + .label-text:before {
  content: '';
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox input[type='checkbox'] + .label-text:after {
  background-color: #4f77ff;
  color: #ffffff;
  border-radius: 2px;
  border-color: #4f77ff;
  line-height: 1.4;
}

.checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.checkbox input[type='checkbox']:focus + .label-text:before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox input[type='checkbox']:checked + .label-text:after {
  font-family: 'FontAwesome';
  content: '\f00c';
}

/*--------------------*/
/* 05. Responsive CSS */
/*--------------------*/

@media (min-width: 768px) {
  .authfy-panel {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .authfy-panel-left,
  .authfy-panel-right {
    box-shadow: none;
  }

  .authfy-panel-left {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .authfy-panel-right {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .authfy-panel-left .brand-col {
    height: 300px;
    text-align: center;
  }

  .term-policy {
    font-size: 75%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .authfy-login .authfy-panel {
    padding: 20px;
  }

  .panel-login .remember-row {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .authfy-login {
    height: 480px;
  }
  .authfy-heading p {
    font-size: 14px;
  }

  .remember-row .col-sm-6 {
    width: 100%;
  }

  .authfy-login .forgotPwd {
    text-align: left;
    margin-top: 0px;
  }
}

.md-display-4 {
  letter-spacing: -.1px; }

.md-display-3 {
  letter-spacing: -.05px; }

.md-display-2, h1, .md-display-1, h2, .md-headline {
  letter-spacing: 0; }

h3, .md-title {
  letter-spacing: .5px; }

h4, .md-subheading-2, h5, .md-subheading-1, p, .md-body-1, h6, .md-body-2 {
  letter-spacing: .1px; }

caption, .md-caption {
  letter-spacing: .2px; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title,
h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin: 0; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title {
  margin-bottom: 14px; }


h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin-bottom: 10px; }


.md-display-3,
.md-display-4,
h3,
.md-title {
  white-space: nowrap; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
h2,
.md-headline,
h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
caption,
.md-caption {
  font-weight: 400; }


h3,
.md-title,
h6,
.md-body-2 {
  font-weight: 500; }


.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 128px; }


.md-display-3 {
  font-size: 56px;
  line-height: 84px; }


.md-display-2 {
  font-size: 45px;
  line-height: 48px; }

h1, .md-display-1 {
  font-size: 34px;
  line-height: 40px; }


h2,
.md-headline {
  font-size: 24px;
  line-height: 32px; }


h3,
.md-title {
  font-size: 20px;
  line-height: 28px; }


h4,
.md-subheading-2 {
  line-height: 28px; }


h5,
.md-subheading-1 {
  line-height: 24px; }


p,
.md-body-1 {
  line-height: 20px; }


h6,
.md-body-2 {
  line-height: 24px; }


caption,
.md-caption {
  font-size: 12px; }

.md-text-left, .md-picker-control {
  text-align: left; }

.md-text-center, .md-calendar-date {
  text-align: center; }

.md-text-right {
  text-align: right; }

.md-text-justify {
  text-align: justify; }

.md-text-capitalize {
  text-transform: capitalize; }

.md-text-lowercalse {
  text-transform: lowercase; }

.md-text-uppercase {
  text-transform: uppercase; }

.md-text-nowrap {
  white-space: nowrap; }

.md-text-no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.md-font-light {
  font-weight: 300; }

.md-font-regular {
  font-weight: 400; }

.md-font-medium, .md-btn .md-icon-text, .md-clock-time-value {
  font-weight: 500; }

.md-font-semibold {
  font-weight: 600; }

.md-font-bold {
  font-weight: 700; }

.md-transition--sharp {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1); }

.md-transition--standard {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.md-transition--acceleration, .md-drop-down-leave.md-drop-down-leave-active {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }


.md-transition--decceleration,
.md-transition--deceleration,
.md-drop-down-enter.md-drop-down-enter-active {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-calendar-date, .md-inline-block {
  display: inline-block;
  vertical-align: bottom; }

.md-full-width {
  width: 100%; }

.md-text-container, .md-bottom-nav .md-icon, .md-block-centered {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.md-grid.md-grid--no-spacing > .md-cell.md-cell--right, .md-cell--right, .md-collapser--card, .md-divider--expand-from-right::after {
  margin-left: auto; }

.md-grid.md-grid--no-spacing > .md-cell.md-cell--right, .md-cell--right, .md-collapser--card, .md-divider--expand-from-right::after {
  margin-left: auto; }

.md-expansion-panel-list, .md-list-unstyled, .md-list, .md-tabs {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.md-media img, .md-media iframe, .md-media svg, .md-media video, .md-media embed, .md-media object {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

@media screen and (min-width: 320px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 16px; }
  .md-body-2,
  .md-body-1 {
    font-size: 14px; }
  h5,
  h4 {
    font-size: 16px; }
  h6,
  p {
    font-size: 14px; } }

@media screen and (min-width: 1025px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 15px; }
  .md-body-2,
  .md-body-1 {
    font-size: 13px; }
  h5,
  h4 {
    font-size: 15px; }
  h6,
  p {
    font-size: 13px; } }

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

html {
  background: #fafafa;
  font-size: 14px;
  min-width: 100%; }

body {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.42857;
  text-rendering: optimizeLegibility; }


h2,
.md-headline,
h3,
.md-title,
h4,
.md-subheading-2,
h5,
.md-subheading-1,
h6,
.md-body-2,
p,
.md-body-1 {
  color: rgba(0, 0, 0, 0.87); }


.md-display-4,
.md-display-3,
.md-display-2, h1, .md-display-1,
caption,
.md-caption {
  color: rgba(0, 0, 0, 0.54); }

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: "Roboto", sans-serif; }

.md-text-container {
  max-width: 640px;
  width: 100%; }
  .md-text-container.md-text-container.md-cell {
    margin-left: auto;
    margin-right: auto; }

.md-fake-btn {
  background: transparent;
  position: relative; }
  .md-fake-btn--no-outline {
    outline-style: none; }

.md-no-scroll.md-no-scroll {
  overflow: hidden;
  position: fixed; }

.md-pointer--hover:hover {
  cursor: pointer; }

.md-pointer--none {
  pointer-events: none; }

.md-content-jump {
  left: -1000px;
  position: absolute;
  top: -1000px; }
  .md-content-jump:active, .md-content-jump:focus {
    left: 0;
    top: 0; }

.md-grid {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto; }
  .md-grid.md-grid--no-spacing {
    padding: 0; }
    .md-grid.md-grid--no-spacing > .md-cell {
      margin: 0; }
  .md-grid--stacked {
    flex-direction: column; }

.md-cell--top {
  align-self: flex-start; }

.md-cell--middle {
  align-self: center; }

.md-cell--center {
  margin-left: auto;
  margin-right: auto; }

.md-cell--bottom {
  align-self: flex-end; }

.md-cell--stretch {
  align-self: stretch; }

@media (max-width: 599px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(100% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 100%; }
  .md-cell--phone-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-phone.md-cell--order-1-phone {
    order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-phone.md-cell--order-2-phone {
    order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-phone.md-cell--order-3-phone {
    order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-phone.md-cell--order-4-phone {
    order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-phone.md-cell--order-5-phone {
    order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-phone.md-cell--order-6-phone {
    order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-phone.md-cell--order-7-phone {
    order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-phone.md-cell--order-8-phone {
    order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-phone.md-cell--order-9-phone {
    order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-phone.md-cell--order-10-phone {
    order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-phone.md-cell--order-11-phone {
    order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-phone.md-cell--order-12-phone {
    order: 12; }
  .md-cell--1,
  .md-cell--1-phone.md-cell--1-phone {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-phone.md-cell--1-phone {
      width: 25%; }
  .md-cell--2,
  .md-cell--2-phone.md-cell--2-phone {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-phone.md-cell--2-phone {
      width: 50%; }
  .md-cell--3,
  .md-cell--3-phone.md-cell--3-phone {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-phone.md-cell--3-phone {
      width: 75%; }
  .md-cell--4,
  .md-cell--4-phone.md-cell--4-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-phone.md-cell--4-phone {
      width: 100%; }
  .md-cell--5,
  .md-cell--5-phone.md-cell--5-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-phone.md-cell--5-phone {
      width: 100%; }
  .md-cell--6,
  .md-cell--6-phone.md-cell--6-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-phone.md-cell--6-phone {
      width: 100%; }
  .md-cell--7,
  .md-cell--7-phone.md-cell--7-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-phone.md-cell--7-phone {
      width: 100%; }
  .md-cell--8,
  .md-cell--8-phone.md-cell--8-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-phone.md-cell--8-phone {
      width: 100%; }
  .md-cell--9,
  .md-cell--9-phone.md-cell--9-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-phone.md-cell--9-phone {
      width: 100%; }
  .md-cell--10,
  .md-cell--10-phone.md-cell--10-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-phone.md-cell--10-phone {
      width: 100%; }
  .md-cell--11,
  .md-cell--11-phone.md-cell--11-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-phone.md-cell--11-phone {
      width: 100%; }
  .md-cell--12,
  .md-cell--12-phone.md-cell--12-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-phone.md-cell--12-phone {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-phone-offset.md-cell--1-phone-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-phone-offset.md-cell--1-phone-offset {
      margin-left: 25%; }
  .md-cell--2-offset,
  .md-cell--2-phone-offset.md-cell--2-phone-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-phone-offset.md-cell--2-phone-offset {
      margin-left: 50%; }
  .md-cell--3-offset,
  .md-cell--3-phone-offset.md-cell--3-phone-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-phone-offset.md-cell--3-phone-offset {
      margin-left: 75%; } }

@media (min-width: 600px) and (max-width: 839px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(50% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 50%; }
  .md-cell--tablet-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-tablet.md-cell--order-1-tablet {
    order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-tablet.md-cell--order-2-tablet {
    order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-tablet.md-cell--order-3-tablet {
    order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-tablet.md-cell--order-4-tablet {
    order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-tablet.md-cell--order-5-tablet {
    order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-tablet.md-cell--order-6-tablet {
    order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-tablet.md-cell--order-7-tablet {
    order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-tablet.md-cell--order-8-tablet {
    order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-tablet.md-cell--order-9-tablet {
    order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-tablet.md-cell--order-10-tablet {
    order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-tablet.md-cell--order-11-tablet {
    order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-tablet.md-cell--order-12-tablet {
    order: 12; }
  .md-cell--1,
  .md-cell--1-tablet.md-cell--1-tablet {
    width: calc(12.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-tablet.md-cell--1-tablet {
      width: 12.5%; }
  .md-cell--2,
  .md-cell--2-tablet.md-cell--2-tablet {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-tablet.md-cell--2-tablet {
      width: 25%; }
  .md-cell--3,
  .md-cell--3-tablet.md-cell--3-tablet {
    width: calc(37.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-tablet.md-cell--3-tablet {
      width: 37.5%; }
  .md-cell--4,
  .md-cell--4-tablet.md-cell--4-tablet {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-tablet.md-cell--4-tablet {
      width: 50%; }
  .md-cell--5,
  .md-cell--5-tablet.md-cell--5-tablet {
    width: calc(62.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-tablet.md-cell--5-tablet {
      width: 62.5%; }
  .md-cell--6,
  .md-cell--6-tablet.md-cell--6-tablet {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-tablet.md-cell--6-tablet {
      width: 75%; }
  .md-cell--7,
  .md-cell--7-tablet.md-cell--7-tablet {
    width: calc(87.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-tablet.md-cell--7-tablet {
      width: 87.5%; }
  .md-cell--8,
  .md-cell--8-tablet.md-cell--8-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-tablet.md-cell--8-tablet {
      width: 100%; }
  .md-cell--9,
  .md-cell--9-tablet.md-cell--9-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-tablet.md-cell--9-tablet {
      width: 100%; }
  .md-cell--10,
  .md-cell--10-tablet.md-cell--10-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-tablet.md-cell--10-tablet {
      width: 100%; }
  .md-cell--11,
  .md-cell--11-tablet.md-cell--11-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-tablet.md-cell--11-tablet {
      width: 100%; }
  .md-cell--12,
  .md-cell--12-tablet.md-cell--12-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-tablet.md-cell--12-tablet {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
    margin-left: calc(12.5% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
      margin-left: 12.5%; }
  .md-cell--2-offset,
  .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
      margin-left: 25%; }
  .md-cell--3-offset,
  .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
    margin-left: calc(37.5% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
      margin-left: 37.5%; }
  .md-cell--4-offset,
  .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--4-offset, .md-grid--no-spacing >
    .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
      margin-left: 50%; }
  .md-cell--5-offset,
  .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
    margin-left: calc(62.5% + 8px); }
    .md-grid--no-spacing > .md-cell--5-offset, .md-grid--no-spacing >
    .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
      margin-left: 62.5%; }
  .md-cell--6-offset,
  .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--6-offset, .md-grid--no-spacing >
    .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
      margin-left: 75%; }
  .md-cell--7-offset,
  .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
    margin-left: calc(87.5% + 8px); }
    .md-grid--no-spacing > .md-cell--7-offset, .md-grid--no-spacing >
    .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
      margin-left: 87.5%; } }

@media (min-width: 840px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(33.33333% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 33.33333%; }
  .md-cell--desktop-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-desktop.md-cell--order-1-desktop {
    order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-desktop.md-cell--order-2-desktop {
    order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-desktop.md-cell--order-3-desktop {
    order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-desktop.md-cell--order-4-desktop {
    order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-desktop.md-cell--order-5-desktop {
    order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-desktop.md-cell--order-6-desktop {
    order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-desktop.md-cell--order-7-desktop {
    order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-desktop.md-cell--order-8-desktop {
    order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-desktop.md-cell--order-9-desktop {
    order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-desktop.md-cell--order-10-desktop {
    order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-desktop.md-cell--order-11-desktop {
    order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-desktop.md-cell--order-12-desktop {
    order: 12; }
  .md-cell--1,
  .md-cell--1-desktop.md-cell--1-desktop {
    width: calc(8.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-desktop.md-cell--1-desktop {
      width: 8.33333%; }
  .md-cell--2,
  .md-cell--2-desktop.md-cell--2-desktop {
    width: calc(16.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-desktop.md-cell--2-desktop {
      width: 16.66667%; }
  .md-cell--3,
  .md-cell--3-desktop.md-cell--3-desktop {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-desktop.md-cell--3-desktop {
      width: 25%; }
  .md-cell--4,
  .md-cell--4-desktop.md-cell--4-desktop {
    width: calc(33.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-desktop.md-cell--4-desktop {
      width: 33.33333%; }
  .md-cell--5,
  .md-cell--5-desktop.md-cell--5-desktop {
    width: calc(41.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-desktop.md-cell--5-desktop {
      width: 41.66667%; }
  .md-cell--6,
  .md-cell--6-desktop.md-cell--6-desktop {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-desktop.md-cell--6-desktop {
      width: 50%; }
  .md-cell--7,
  .md-cell--7-desktop.md-cell--7-desktop {
    width: calc(58.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-desktop.md-cell--7-desktop {
      width: 58.33333%; }
  .md-cell--8,
  .md-cell--8-desktop.md-cell--8-desktop {
    width: calc(66.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-desktop.md-cell--8-desktop {
      width: 66.66667%; }
  .md-cell--9,
  .md-cell--9-desktop.md-cell--9-desktop {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-desktop.md-cell--9-desktop {
      width: 75%; }
  .md-cell--10,
  .md-cell--10-desktop.md-cell--10-desktop {
    width: calc(83.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-desktop.md-cell--10-desktop {
      width: 83.33333%; }
  .md-cell--11,
  .md-cell--11-desktop.md-cell--11-desktop {
    width: calc(91.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-desktop.md-cell--11-desktop {
      width: 91.66667%; }
  .md-cell--12,
  .md-cell--12-desktop.md-cell--12-desktop {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-desktop.md-cell--12-desktop {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
    margin-left: calc(8.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
      margin-left: 8.33333%; }
  .md-cell--2-offset,
  .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
    margin-left: calc(16.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
      margin-left: 16.66667%; }
  .md-cell--3-offset,
  .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
      margin-left: 25%; }
  .md-cell--4-offset,
  .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
    margin-left: calc(33.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--4-offset, .md-grid--no-spacing >
    .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
      margin-left: 33.33333%; }
  .md-cell--5-offset,
  .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
    margin-left: calc(41.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--5-offset, .md-grid--no-spacing >
    .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
      margin-left: 41.66667%; }
  .md-cell--6-offset,
  .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--6-offset, .md-grid--no-spacing >
    .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
      margin-left: 50%; }
  .md-cell--7-offset,
  .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
    margin-left: calc(58.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--7-offset, .md-grid--no-spacing >
    .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
      margin-left: 58.33333%; }
  .md-cell--8-offset,
  .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
    margin-left: calc(66.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--8-offset, .md-grid--no-spacing >
    .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
      margin-left: 66.66667%; }
  .md-cell--9-offset,
  .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--9-offset, .md-grid--no-spacing >
    .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
      margin-left: 75%; }
  .md-cell--10-offset,
  .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
    margin-left: calc(83.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--10-offset, .md-grid--no-spacing >
    .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
      margin-left: 83.33333%; }
  .md-cell--11-offset,
  .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
    margin-left: calc(91.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--11-offset, .md-grid--no-spacing >
    .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
      margin-left: 91.66667%; } }

.md-autocomplete-container {
  position: relative; }

.md-autocomplete-suggestion {
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.15;
  overflow: hidden;
  position: absolute;
  top: 12px;
  white-space: nowrap; }

@media screen and (min-width: 320px) {
  .md-autocomplete-suggestion {
    font-size: 16px; }
    .md-autocomplete-suggestion--floating {
      top: 37px; }
    .md-autocomplete-suggestion--block {
      top: 18px; } }

@media screen and (min-width: 1025px) {
  .md-autocomplete-suggestion {
    font-size: 13px; }
    .md-autocomplete-suggestion--floating {
      top: 33px; }
    .md-autocomplete-suggestion--block {
      top: 15px; } }

.md-avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  text-align: center;
  width: 40px; }
  .md-avatar .md-icon {
    color: inherit; }
  .md-avatar--icon-sized {
    height: 24px;
    width: 24px; }

.md-avatar-img {
  height: 100%;
  width: auto; }

.md-avatar-content {
  align-items: center;
  display: flex;
  font-size: 24px;
  height: 100%;
  justify-content: center;
  width: 100%; }

.md-avatar--default {
  background: #616161;
  color: #f5f5f5; }

@media screen and (min-width: 1025px) {
  .md-avatar-content {
    font-size: 20px; }
  .md-avatar--icon-sized {
    height: 20px;
    width: 20px; } }

.md-avatar--red {
  background: #d50000;
  color: #ffebee; }

.md-avatar--pink {
  background: #d81b60;
  color: #fff; }

.md-avatar--purple {
  background: #7b1fa2;
  color: #e1bee7; }

.md-avatar--deep-purple {
  background: #311b92;
  color: #d1c4e9; }

.md-avatar--indigo {
  background: #3949ab;
  color: #c5cae9; }

.md-avatar--blue {
  background: #2962ff;
  color: #fff; }

.md-avatar--light-blue {
  background: #4fc3f7;
  color: #311b92; }

.md-avatar--cyan {
  background: #26c6da;
  color: #004d40; }

.md-avatar--teal {
  background: #1de9b6;
  color: #004d40; }

.md-avatar--green {
  background: #2e7d32;
  color: #e8f5e9; }

.md-avatar--light-green {
  background: #aed581;
  color: #1b5e20; }

.md-avatar--lime {
  background: #d4e157;
  color: #00695c; }

.md-avatar--yellow {
  background: #ff0;
  color: #795548; }

.md-avatar--amber {
  background: #ffca28;
  color: #4e342e; }

.md-avatar--orange {
  background: #fb8c00;
  color: #212121; }

.md-avatar--deep-orange {
  background: #ff3d00;
  color: #212121; }

.md-avatar--brown {
  background: #795548;
  color: #efebe9; }

.md-avatar--grey {
  background: #616161;
  color: #f5f5f5; }

.md-avatar--blue-grey {
  background: #455a64;
  color: #eceff1; }

.md-badge-container {
  position: relative; }

.md-badge {
  position: absolute;
  right: -8px;
  top: -8px; }
  .md-badge--circular {
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 10px;
    height: 24px;
    justify-content: center;
    width: 24px; }
  .md-badge--default {
    background: rgba(0, 0, 0, 0.2); }

.md-bottom-navigation {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 11; }
  .md-bottom-navigation--shifting {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: background;
    transition-property: background; }
  .md-bottom-navigation--dynamic {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: background, -webkit-transform;
    transition-property: background, -webkit-transform;
    transition-property: background, transform;
    transition-property: background, transform, -webkit-transform; }
    .md-bottom-navigation--dynamic-inactive {
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0); }

.md-bottom-navigation-offset {
  padding-bottom: 56px; }

.md-bottom-nav {
  color: inherit;
  display: block;
  flex-grow: 1;
  font-size: 12px;
  height: 56px;
  max-width: 168px;
  padding: 8px 12px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-bottom-nav--active {
    flex-shrink: 0;
    font-size: 14px;
    padding-top: 6px; }
  .md-bottom-nav--fixed {
    min-width: 80px; }
  .md-bottom-nav--shifting {
    min-width: 96px;
    position: static;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: max-width;
    transition-property: max-width; }
    .md-bottom-nav--shifting-inactive {
      max-width: 96px;
      min-width: 56px;
      padding-top: 16px; }
    .md-bottom-nav--shifting .md-ink-container {
      overflow: visible; }
    .md-bottom-nav--shifting .md-ink {
      background: rgba(255, 255, 255, 0.12); }
  .md-bottom-nav-label {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: color, font-size;
    transition-property: color, font-size; }
    .md-bottom-nav-label--shifting-inactive {
      max-width: 32px;
      overflow: hidden;
      white-space: nowrap; }

a.md-btn {
  text-decoration: none; }

.md-btn {
  background: transparent;
  border: 0;
  position: relative;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, color;
  transition-property: background, color; }
  .md-btn[disabled] * {
    pointer-events: none; }
  .md-btn--tooltip {
    overflow: visible; }
  .md-btn:focus {
    outline-style: none; }
  .md-btn .md-icon-separator {
    height: 100%; }

.md-btn--hover {
  background: rgba(153, 153, 153, 0.12); }

.md-btn--color-primary-active {
  background: rgba(13, 17, 43, 0.12); }

.md-btn--color-secondary-active {
  background: rgba(1, 158, 225, 0.12); }

.md-btn--text {
  border-radius: 2px;
  font-weight: 500;
  min-width: 88px;
  padding: 8px 16px;
  text-transform: uppercase; }

.md-btn--raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: background 0.15s, box-shadow 0.3s, color 0.15s;
  transition: background 0.15s, box-shadow 0.3s, color 0.15s; }

.md-btn--raised-disabled {
  background: rgba(0, 0, 0, 0.12); }

.md-btn--raised-pressed {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4); }

.md-btn--icon {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  height: 48px;
  padding: 12px;
  width: 48px; }

.md-btn--floating {
  height: 56px;
  padding: 16px;
  -webkit-transition-property: background, box-shadow, color;
  transition-property: background, box-shadow, color;
  width: 56px; }

.md-btn--floating-mini {
  height: 40px;
  padding: 8px;
  width: 40px; }

.md-btn--fixed {
  position: fixed;
  z-index: 10; }

@media screen and (min-width: 320px) {
  .md-btn--text {
    height: 36px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 14px; }
    .md-btn--text::before, .md-btn--text::after {
      content: '';
      height: 6px;
      left: 0;
      position: absolute;
      right: 0; }
    .md-btn--text::before {
      top: -6px; }
    .md-btn--text::after {
      bottom: -6px; }
  .md-btn--fixed-tl {
    left: 16px;
    top: 16px; }
  .md-btn--fixed-tr {
    right: 16px;
    top: 16px; }
  .md-btn--fixed-bl {
    bottom: 16px;
    left: 16px; }
  .md-btn--fixed-br {
    bottom: 16px;
    right: 16px; } }

@media screen and (min-width: 1025px) {
  .md-btn--text {
    height: 32px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 13px; }
    .md-btn--text::before, .md-btn--text::after {
      display: none;
      visibility: hidden; }
    .md-btn--text::before {
      top: 0; }
    .md-btn--text::after {
      bottom: 0; }
  .md-btn--fixed-tl {
    left: 24px;
    top: 24px; }
  .md-btn--fixed-tr {
    right: 24px;
    top: 24px; }
  .md-btn--fixed-bl {
    bottom: 24px;
    left: 24px; }
  .md-btn--fixed-br {
    bottom: 24px;
    right: 24px; }
  .md-btn--icon {
    height: 40px;
    width: 40px;
    padding: 10px; }
  .md-btn--floating {
    height: 48px;
    padding: 14px;
    width: 48px; }
    .md-btn--floating-mini {
      height: 40px;
      padding: 10px;
      width: 40px; } }

.md-card {
  display: block; }
  .md-card--raise {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow; }

.md-collapser--card {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: background, transform;
  transition-property: background, transform, -webkit-transform; }

.md-card-text {
  font-size: 14px;
  padding: 16px; }
  .md-card-text p {
    font-size: inherit; }
    .md-card-text p:last-child {
      margin-bottom: 0; }
  .md-card-text:last-child {
    padding-bottom: 24px; }

.md-card-title {
  align-items: center;
  display: flex;
  padding: 16px; }
  .md-card-title:last-child {
    padding-bottom: 24px; }
  .md-card-title--primary {
    padding-top: 24px; }
  .md-card-title--title {
    font-size: 14px;
    line-height: 1.42857;
    margin: 0;
    white-space: normal; }
  .md-card-title--large {
    font-size: 24px; }
  .md-card-title--one-line {
    overflow: hidden; }
    .md-card-title--one-line .md-card-title--title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.md-avatar--card {
  flex-shrink: 0;
  margin-right: 16px; }

.md-dialog-footer--card {
  align-items: center;
  display: flex;
  justify-content: flex-start; }

.md-dialog-footer--card-centered {
  justify-content: center; }

.md-card--table .md-card-title {
  padding-left: 24px; }

.md-chip {
  align-items: center;
  background: #e0e0e0;
  border: 0;
  border-radius: 16px;
  display: inline-flex;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: box-shadow, background;
  transition-property: box-shadow, background;
  vertical-align: top;
  white-space: nowrap; }
  .md-chip:focus {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    outline-style: none; }

.md-chip--hover.md-chip--hover {
  background: #616161; }

.md-chip-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color;
  transition-property: color; }

.md-chip .md-avatar {
  border: 0;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px; }

.md-chip--avatar {
  padding-left: 40px; }

.md-chip-icon {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
  margin-right: 4px;
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 1; }

.md-chip-icon--rotate {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg); }

.md-chip--remove {
  padding-right: 32px; }

.md-chip-text--contact {
  font-size: 14px; }

.md-chip-text--hover {
  color: #fff; }

@media screen and (min-width: 1025px) {
  .md-chip-icon {
    margin-left: 6px;
    margin-right: 6px;
    top: 6px; } }

.md-collapser.md-collapser {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-collapser .md-tooltip-container {
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-collapser--flipped {
  -webkit-transform: rotate3d(0, 0, 1, 180deg);
          transform: rotate3d(0, 0, 1, 180deg); }
  .md-collapser--flipped .md-tooltip-container {
    -webkit-transform: rotate3d(0, 0, 1, -180deg);
            transform: rotate3d(0, 0, 1, -180deg); }

.md-data-table {
  border-collapse: collapse;
  max-width: 100%; }
  .md-data-table--full-width {
    width: 100%; }
  .md-data-table--responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; }

thead .md-table-row,
tbody .md-table-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

tbody .md-table-row {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background;
  transition-property: background; }
  tbody .md-table-row--active {
    background: #f5f5f5; }
  @media (min-device-width: 1025px) {
    tbody .md-table-row--hover {
      background: #eee; } }

.md-table-column {
  line-height: normal;
  padding-bottom: 0;
  padding-right: 24px;
  padding-top: 0;
  vertical-align: middle;
  white-space: nowrap; }
  .md-table-column:first-child {
    padding-left: 24px; }
  .md-table-column--relative {
    position: relative; }
  .md-table-column--adjusted {
    padding-right: 56px; }
  .md-table-column--header {
    font-size: 12px;
    font-weight: 500;
    height: 56px; }
    .md-table-column--header .md-icon {
      font-size: 16px; }
  .md-table-column--data {
    font-size: 13px;
    height: 48px; }
  .md-table-column--plain {
    height: 48px;
    white-space: normal; }
  .md-table-column--grow {
    width: 100%; }
  .md-table-column--select-header {
    padding-left: 16px; }
  .md-table-column .md-icon-separator {
    line-height: inherit; }

.md-table-checkbox .md-selection-control-label {
  display: flex; }

.md-table-checkbox .md-selection-control-container {
  margin-left: 12px;
  margin-right: 12px; }

.md-edit-dialog.md-dialog {
  width: 250px; }

.md-edit-dialog__label {
  overflow: hidden;
  padding: 16px 0;
  text-overflow: ellipsis;
  width: 250px; }
  @media screen and (max-width: 1024px) {
    .md-edit-dialog__label {
      font-size: 16px; } }

.md-edit-dialog__content {
  display: flex;
  padding: 24px;
  padding-bottom: 8px; }
  .md-edit-dialog__content:not(:first-child) {
    padding-top: 0; }

.md-edit-dialog__header {
  font-weight: 500; }
  .md-edit-dialog__header.md-text-field {
    font-size: 12px; }

.md-edit-dialog__blocked-field {
  height: 47px;
  width: 250px; }
  .md-edit-dialog__blocked-field.md-edit-dialog__blocked-field {
    padding-bottom: 0;
    padding-top: 0; }
  .md-edit-dialog__blocked-field .md-text-field-icon-container {
    align-items: center; }

.md-table-column--select-field {
  padding-left: 24px; }

.md-select-field-column .md-select-field--btn {
  height: 47px; }

.md-table-footer--pagination .md-table-column {
  padding-left: 0; }

.md-table-pagination {
  height: 56px; }
  .md-table-pagination--controls {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    position: absolute;
    white-space: nowrap; }

@media (max-width: 767px) {
  .md-table-pagination .md-text-field {
    font-size: 13px; }
  .md-table-pagination .md-icon-text:first-child {
    padding-right: 4px; }
  .md-table-pagination__label {
    display: none; } }

.md-table-card-header {
  position: relative; }
  .md-table-card-header--no-title {
    align-items: center;
    display: flex;
    height: 80px;
    padding-right: 2px; }
  .md-table-card-header .md-card-title {
    padding-right: 2px; }
    .md-table-card-header .md-card-title:last-child {
      padding-bottom: 16px; }
  .md-table-card-header .md-btn--dialog + .md-btn--dialog {
    margin-left: 8px; }

.md-card-title--contextual {
  background: #019ee1;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }

.md-card-title--title-contextual {
  color: #019ee1;
  font-size: 16px;
  font-weight: 500;
  line-height: 80px; }

.md-drop-down-enter {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0); }
  .md-drop-down-enter.md-drop-down-enter-active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-drop-down-leave {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  .md-drop-down-leave.md-drop-down-leave-active {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-data-table--fixed {
  overflow-y: hidden; }

.md-data-table__fixed-wrapper {
  display: table;
  min-width: 100%;
  position: relative; }
  .md-data-table__fixed-wrapper--header {
    padding-top: 56px; }
  .md-data-table__fixed-wrapper--footer {
    padding-bottom: 48px; }

.md-data-table__scroll-wrapper {
  overflow-x: hidden;
  overflow-y: auto; }

.md-table-column--fixed {
  height: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
  white-space: nowrap; }
  .md-table-column--fixed > * {
    display: none; }
  .md-table-column--fixed .md-table-column__fixed {
    display: block; }

.md-table-column__fixed {
  position: absolute;
  visibility: visible; }
  .md-table-column__fixed--header {
    top: 0; }
  .md-table-column__fixed--footer {
    bottom: 0; }
  .md-table-column__fixed--flex {
    align-items: center;
    display: flex; }
    .md-table-column__fixed--flex-right {
      justify-content: flex-end; }
  .md-table-column__fixed .md-table-checkbox--header {
    display: flex;
    height: 56px; }
  .md-table-column__fixed .md-table-checkbox--footer {
    display: flex;
    height: 48px; }

.md-dialog-container.md-overlay {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  z-index: 20; }

.md-dialog {
  width: 280px;
  cursor: auto;
  position: fixed; }

.md-dialog--centered {
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-height: calc(100% - 48px);
  max-width: calc(100% - 80px); }
  .md-dialog--centered .md-list {
    padding-bottom: 8px;
    padding-top: 0; }
  .md-dialog--centered .md-list-tile {
    height: auto;
    padding: 16px 24px; }
  .md-dialog--centered .md-tile-text--primary {
    white-space: normal; }

.md-dialog--centered-enter {
  -webkit-transform: translate3d(-50%, calc(-50% + -30px), 0);
          transform: translate3d(-50%, calc(-50% + -30px), 0); }
  .md-dialog--centered-enter.md-dialog--centered-enter-active {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--centered-leave {
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }
  .md-dialog--centered-leave.md-dialog--centered-leave-active {
    -webkit-transform: translate3d(-50%, calc(-50% + -30px), 0);
            transform: translate3d(-50%, calc(-50% + -30px), 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--full-page {
  bottom: 0;
  left: 0;
  overflow: auto;
  top: 0;
  width: 100vw;
  z-index: 110; }

.md-dialog--full-page-enter {
  -webkit-transform: scale(0);
          transform: scale(0); }
  .md-dialog--full-page-enter.md-dialog--full-page-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--full-page-leave {
  -webkit-transform: scale(1);
          transform: scale(1); }
  .md-dialog--full-page-leave.md-dialog--full-page-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-title--dialog {
  margin-bottom: 0;
  padding: 24px;
  padding-bottom: 20px;
  white-space: normal; }

.md-dialog-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.md-dialog-content--padded {
  padding: 24px; }
  .md-dialog-content--padded:not(:first-child) {
    padding-top: 0; }

.md-dialog-footer {
  display: flex;
  justify-content: flex-end; }

.md-dialog-footer--inline {
  padding: 8px; }
  .md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
    margin-left: 8px; }

.md-dialog-footer--stacked {
  align-items: flex-end;
  flex-direction: column;
  padding-bottom: 8px;
  padding-right: 8px; }
  .md-dialog-footer--stacked .md-btn--dialog {
    margin-bottom: 6px;
    margin-top: 6px; }

.md-btn--dialog {
  height: 36px;
  min-width: 64px;
  padding-left: 8px;
  padding-right: 8px; }

.md-divider {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  content: '';
  display: block;
  height: 1px;
  margin: 0; }

.md-divider--vertical {
  height: 100%;
  width: 1px; }

.md-divider--inset {
  margin-left: 72px; }

.md-divider-border {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0; }

.md-divider-border--top {
  border-top-width: 1px; }

.md-divider-border--right {
  border-right-width: 1px; }

.md-divider-border--bottom {
  border-bottom-width: 1px; }

.md-divider-border--left {
  border-left-width: 1px; }

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .md-drawer--left {
    max-width: 320px;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    width: calc(100vw - 56px); }
  .md-drawer--right {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .md-drawer--mini.md-drawer--mini {
    width: 48px; }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 12px;
    padding-right: 12px; }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 48px; }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 56px); } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 48px); } }

@media screen and (min-width: 768px) {
  .md-drawer--left {
    max-width: 400px;
    -webkit-transform: translate3d(-256px, 0, 0);
            transform: translate3d(-256px, 0, 0);
    width: 256px; }
  .md-drawer--right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .md-drawer--mini.md-drawer--mini {
    width: 72px; }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 26px;
    padding-right: 26px; }
  .md-drawer-relative {
    margin-left: 256px; }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 72px; }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 64px); } }

.md-drawer {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-drawer--fixed {
    bottom: 0;
    position: fixed;
    top: 0;
    z-index: 17; }
  .md-drawer--inline {
    display: inline-block;
    height: 100%; }
  .md-drawer--left {
    left: 0; }
  .md-drawer--right {
    right: 0; }
  .md-drawer--active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .md-drawer--mini {
    z-index: 16; }

.md-list--drawer {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: auto; }

.md-overlay--drawer.md-overlay--drawer {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

.md-expansion-panel {
  background: #fff;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: margin;
  transition-property: margin; }
  .md-expansion-panel--expanded:not(:first-child) {
    margin-top: 16px; }
  .md-expansion-panel--expanded:not(:last-child) {
    margin-bottom: 16px; }

.md-panel-column:not(:last-child) {
  padding-right: 16px; }

.md-panel-column--overflown {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word; }

.md-panel-column:not(:last-child) {
  padding-right: 16px; }

.md-panel-header {
  align-items: center;
  display: flex;
  font-size: 15px;
  height: 48px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, height;
  transition-property: background, height; }
  .md-panel-header--expanded {
    height: 64px; }
  .md-panel-header--focused {
    background: #eee; }

.md-panel-content {
  padding: 0 24px 16px; }

.md-panel-secondary-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px; }

.md-file-input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0; }

.md-file-input-container {
  position: relative; }
  .md-file-input-container .md-btn {
    display: block; }

.md-ink-container {
  border-radius: inherit;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }

.md-ink-container--2x {
  height: 200%;
  left: -50%;
  top: -50%;
  width: 200%; }

.md-ink {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: block;
  opacity: 1;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0);
  z-index: -1; }

.md-ink--active {
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-ink--expanded {
  -webkit-transform: scale(1);
          transform: scale(1); }

.md-ink--leaving {
  opacity: 0;
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
  text-align: center;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

svg.md-icon {
  fill: currentColor;
  height: 24px;
  width: 24px; }

.md-icon-separator {
  align-items: center;
  display: flex;
  font-weight: inherit;
  text-align: left; }
  .md-icon-separator .md-icon {
    flex-grow: 0;
    flex-shrink: 0; }

.md-icon-text {
  flex-grow: 1;
  flex-shrink: 0;
  font-weight: inherit;
  line-height: inherit; }
  .md-icon-text:first-child {
    padding-right: 16px; }
  .md-icon-text:last-child {
    padding-left: 16px; }

@media screen and (min-width: 1025px) {
  .material-icons.md-icon {
    font-size: 20px; }
  svg.md-icon {
    height: 20px;
    width: 20px; } }

.md-layover-enter {
  -webkit-transform: scale(0);
          transform: scale(0); }
  .md-layover-enter.md-layover-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-layover-leave {
  opacity: 1; }
  .md-layover-leave.md-layover-leave-active {
    opacity: 0;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-layover-child {
  position: fixed;
  z-index: 100; }
  .md-layover-child--tl {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
  .md-layover-child--tr {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0; }
  .md-layover-child--bl {
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%; }
  .md-layover-child--br {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  .md-layover-child--below {
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
    .md-layover-child--below.md-layover-enter {
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
      .md-layover-child--below.md-layover-enter.md-layover-enter-active {
        -webkit-transform: scaleY(1);
                transform: scaleY(1); }

.md-layover--simplified {
  position: relative; }

.md-layover-child--simplified {
  position: absolute; }

.md-list {
  background: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-list .md-avatar,
  .md-list .md-icon {
    flex-shrink: 0; }
  .md-list .md-divider:not(.md-list-item--divider-vertical) {
    margin-bottom: 8px;
    margin-top: 8px; }
  .md-list .md-list:not(.md-list--menu) {
    background: inherit; }

.md-list-tile {
  align-items: center;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background;
  transition-property: background; }
  .md-list-tile--active {
    background: rgba(0, 0, 0, 0.12); }

.md-list-item--inset {
  padding-left: 72px; }

.md-list-item--flex {
  display: flex; }

.md-list-item--button-grow {
  flex-grow: 1; }

.md-list-item--divider-vertical {
  flex-shrink: 0;
  height: calc(100% - 4px);
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  width: 2px; }

.md-list-item--children-inline {
  align-self: center;
  padding-right: 2px; }

.md-tile-content {
  flex-grow: 1;
  overflow: hidden; }
  .md-tile-content--left-icon {
    padding-left: 32px; }
  .md-tile-content--left-avatar {
    padding-left: 16px; }
  .md-tile-content--right-padding {
    padding-right: 16px; }

.md-tile-addon {
  line-height: 1.42857; }
  .md-tile-addon--icon {
    height: 24px; }
  .md-tile-addon--avatar {
    height: 40px; }

.md-text--theme-primary .md-icon {
  color: inherit; }

.md-tile-text--primary, .md-tile-text--secondary {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.md-tile-text--three-lines {
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }

.md-list-tile--three-lines .md-tile-addon {
  align-self: flex-start; }

.md-list--nested-1 .md-list-tile {
  padding-left: 72px; }

.md-list--nested-2 .md-list-tile {
  padding-left: 108px; }

.md-list-control {
  flex-grow: 1; }
  .md-list-control .md-selection-control-label {
    width: 100%; }
    .md-list-control .md-selection-control-label > span {
      width: 100%; }

.md-tile-content--left-button {
  padding-left: 24px; }

.md-list-control--right {
  justify-content: flex-end; }

.md-list-tile--control-left {
  padding-left: 0; }

.md-list-tile--control-right {
  padding-right: 0; }

@media screen and (min-width: 320px) {
  .md-list {
    padding-bottom: 8px;
    padding-top: 8px; }
  .md-list-tile {
    height: 48px; }
  .md-tile-text--primary {
    font-size: 16px; }
  .md-tile-text--secondary {
    font-size: 14px; }
  .md-list-tile--avatar {
    height: 56px; }
  .md-list-tile--two-lines {
    height: 72px; }
  .md-list-tile--three-lines {
    height: 88px; }
    .md-list-tile--three-lines .md-tile-text--secondary {
      height: 40px; }
    .md-list-tile--three-lines .md-tile-addon {
      margin-top: 14px; } }

@media screen and (min-width: 1025px) {
  .md-list {
    padding-bottom: 4px;
    padding-top: 4px; }
  .md-list-tile {
    height: 40px; }
  .md-tile-text--primary, .md-tile-text--secondary {
    font-size: 13px; }
  .md-list-tile--avatar {
    height: 48px; }
  .md-list-tile--two-lines {
    height: 60px; }
  .md-list-tile--three-lines {
    height: 76px; }
    .md-list-tile--three-lines .md-tile-text--secondary {
      height: 37.14286px; }
    .md-list-tile--three-lines .md-tile-addon {
      margin-top: 12px; }
  .md-tile-addon--icon {
    height: 20px; }
  .md-tile-content--left-icon {
    padding-left: 36px; } }

.md-list--inline {
  display: flex;
  padding: 0; }

.md-media {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }

.md-media--16-9 {
  padding-bottom: 56.25%; }

.md-media--4-3 {
  padding-bottom: 75%; }

.md-media--1-1 {
  padding-bottom: 100%; }

.md-media-overlay {
  background: rgba(0, 0, 0, 0.54);
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }
  .md-media-overlay .md-btn,
  .md-media-overlay .md-text {
    color: #fff; }
  .md-media-overlay .md-text--secondary {
    color: rgba(255, 255, 255, 0.7); }

.md-list--menu {
  min-width: 112px; }
  .md-list--menu-restricted {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .md-list--menu-contained {
    width: 100%; }
  .md-list--menu-below {
    left: 0;
    top: 100%;
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
  .md-list--menu-tr {
    right: 0;
    top: 0;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0; }
  .md-list--menu-tl {
    top: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
  .md-list--menu-br {
    right: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  .md-list--menu-bl {
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%; }

@media screen and (min-width: 1025px) {
  .md-list--menu-cascading {
    padding-bottom: 16px;
    padding-top: 16px; }
    .md-list--menu-cascading .md-list-tile {
      padding-left: 24px;
      padding-right: 24px; }
      .md-list--menu-cascading .md-list-tile:not(.md-list-tile--two-lines):not(.md-list-tile--three-lines) {
        height: 32px; }
    .md-list--menu-cascading .md-tile-text--primary {
      font-size: 15px; }
    .md-list--menu-cascading .md-collapser {
      -webkit-transform: rotate3d(0, 0, 1, -90deg);
              transform: rotate3d(0, 0, 1, -90deg); }
    .md-list--menu-cascading .md-collapser--flipped {
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
              transform: rotate3d(0, 0, 1, 90deg); } }

@media screen and (min-width: 320px) {
  .md-list--menu-restricted {
    max-height: 272px; } }

@media screen and (min-width: 1025px) {
  .md-list--menu-restricted {
    max-height: 264px; } }

@media screen and (min-width: 320px) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 56px); } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 48px); } }

@media screen and (min-width: 768px) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 64px); }
  .md-title.md-title--persistent-offset {
    margin-left: 216px; } }

@media screen and (min-width: 1025px) {
  .md-title.md-title--persistent-offset {
    margin-left: 226px; } }

.md-toolbar.md-toolbar--over-drawer {
  z-index: 19; }

.md-title--drawer-active.md-title--drawer-active {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: margin-left;
  transition-property: margin-left; }

.md-navigation-drawer-content {
  display: block; }
  .md-navigation-drawer-content:focus {
    outline-style: none; }
  .md-navigation-drawer-content--inactive {
    margin-left: 0; }
  .md-navigation-drawer-content--active {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: margin-left;
    transition-property: margin-left; }
  .md-navigation-drawer-content--prominent-offset {
    min-height: calc(100vh - 128px); }

.md-title.md-title--permanent-offset {
  margin-left: 276px; }

.md-cross-fade-enter {
  opacity: .01;
  -webkit-transform: translate3d(0, 16px, 0);
          transform: translate3d(0, 16px, 0); }
  .md-cross-fade-enter.md-cross-fade-enter-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform; }

.md-overlay {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  z-index: 16; }
  .md-overlay--active {
    opacity: 1; }

.md-paper--0 {
  box-shadow: none; }

@media screen and (min-width: 1025px) {
  .md-paper--0-hover {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow; }
    .md-paper--0-hover:hover {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); } }

.md-paper--1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.md-paper--2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4); }

.md-paper--3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); }

.md-paper--4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4); }

.md-paper--5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4); }

.md-picker-container {
  position: relative; }

.md-picker-content-container {
  background: #fff; }

.md-picker--inline {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 12; }

.md-picker--inline-icon {
  left: 40px; }

.md-picker-control {
  padding: 0; }

.md-picker-text {
  color: rgba(255, 255, 255, 0.7); }
  .md-picker-text.md-picker-text > * {
    color: inherit; }
  .md-picker-text > * {
    font-weight: inherit;
    margin: 0;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: color, font;
    transition-property: color, font; }

.md-picker-text--active {
  color: #fff;
  font-weight: 500; }

.md-dialog--picker {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 16px);
  max-width: calc(100% - 16px);
  overflow: auto;
  width: auto; }

.md-dialog-content--picker {
  padding: 0; }

.md-picker-header {
  background: #0d112b;
  padding: 24px; }

@media (orientation: portrait) {
  .md-picker {
    width: 330px; }
    .md-picker .md-picker-header {
      height: 110px; }
    .md-picker .md-time-periods {
      display: inline-block;
      padding-left: 1em;
      padding-right: 24px; }
    .md-picker .md-picker-content {
      height: 340px; }
    .md-picker .md-display-3 {
      font-size: 5em;
      line-height: 1; }
    .md-picker .md-calendar-dows {
      padding-bottom: 12px; }
    .md-picker.md-picker--date .md-display-1 {
      display: inline-block; }
    .md-picker .md-calendar-date--btn {
      height: 44px; }
      .md-picker .md-calendar-date--btn::after {
        height: 36px;
        width: 36px; }
    .md-picker .md-clock-face {
      height: 282px;
      width: 282px; }
    .md-picker .md-clock-hand {
      width: 121px; }
      .md-picker .md-clock-hand--inner {
        width: 85px; } }

@media (orientation: landscape) {
  .md-picker {
    display: flex;
    width: 560px; }
    .md-picker .md-picker-header {
      width: 190px; }
    .md-picker .md-picker-content {
      height: 280px;
      width: 370px; }
    .md-picker .md-calendar-date--btn {
      height: 35px; }
      .md-picker .md-calendar-date--btn::after {
        height: 32px;
        width: 32px; }
    .md-picker.md-picker--time .md-picker-header {
      padding-top: 93.33333px; }
    .md-picker .md-clock-face {
      height: 244px;
      width: 244px; }
    .md-picker .md-clock-hand {
      width: 102px; }
      .md-picker .md-clock-hand--inner {
        width: 66px; }
    .md-picker .md-time-periods {
      margin-left: auto;
      margin-right: 2.5em;
      width: 35px; }
    .md-picker .md-display-3 {
      font-size: 3.25em; } }

@media (max-width: 320px) and (orientation: portrait) {
  .md-picker {
    width: 304px; } }

@media (max-height: 320px) and (orientation: landscape) {
  .md-picker .md-picker-content {
    height: 256px;
    width: calc(100vw - 206px); } }

.md-picker--date .md-picker-control {
  display: block; }

.md-picker-content--calendar {
  padding-left: 12px;
  padding-right: 12px; }

.md-calendar-controls {
  align-items: center;
  display: flex; }
  .md-calendar-controls .md-title {
    flex-grow: 1;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center; }

.md-calendar-date {
  margin: 0;
  width: calc(100% / 7); }

.md-picker-content--year {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.md-years {
  list-style: none;
  margin: 0;
  padding: 0; }

.md-year {
  font-size: 16px;
  padding: 12px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color, font-size;
  transition-property: color, font-size; }
  .md-year:focus:not(.md-year--active) {
    font-size: 20px; }
  .md-year--active {
    font-size: 24px;
    font-weight: 500; }

.md-calendar-date--btn::after {
  background: #0d112b;
  border-radius: 50%;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
          transform: translateX(-50%) translateY(-50%) scale(0);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  z-index: 0; }

.md-calendar-date--btn-active {
  font-weight: 700; }
  .md-calendar-date--btn-active::after {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1); }

.md-calendar-date--date {
  position: relative;
  z-index: 1; }

.md-calendar-dow {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.42857; }

.md-picker-content--clock {
  padding: 24px;
  padding-bottom: 12px; }

.md-time-periods .md-picker-control {
  display: block; }

.md-time-period {
  font-size: 1.14286em;
  font-weight: 500;
  margin: 0;
  padding: 0; }

.md-clock-face {
  background: #f5f5f5;
  border-radius: 50%;
  position: relative; }

.md-clock-hand {
  height: 2px;
  position: absolute;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: width;
  transition-property: width;
  z-index: 1; }
  .md-clock-hand--active {
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform; }
  .md-clock-hand::before, .md-clock-hand::after {
    background: #0d112b;
    border-radius: 50%;
    content: '';
    position: absolute; }
  .md-clock-hand::before {
    height: 6px;
    left: -3px;
    top: -2px;
    width: 6px; }
  .md-clock-hand::after {
    height: 36px;
    right: -16px;
    top: -18px;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: background, border, -webkit-transform;
    transition-property: background, border, -webkit-transform;
    transition-property: background, border, transform;
    transition-property: background, border, transform, -webkit-transform;
    width: 36px; }
  .md-clock-hand--minute-hover::after {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }

.md-clock-time {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  width: 36px;
  z-index: 8; }
  .md-clock-time:focus {
    outline-style: none; }

.md-progress {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em; }

.md-progress--circular-determinate {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-progress--circular-indeterminate {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-circular-progress;
          animation-name: md-circular-progress;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.md-circular-progress-path {
  fill: none;
  stroke: #019ee1;
  stroke-dasharray: 187px; }

.md-circular-progress-path--animated {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-circular-progress-dash;
          animation-name: md-circular-progress-dash;
  -webkit-animation-timing-function: ease-ine-out;
          animation-timing-function: ease-ine-out;
  stroke-dashoffset: 0; }

@-webkit-keyframes md-circular-progress {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@keyframes md-circular-progress {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@-webkit-keyframes md-circular-progress-dash {
  0% {
    stroke-dashoffset: 187px; }
  50% {
    stroke-dashoffset: 46.75px; }
  100% {
    stroke-dashoffset: 187px; } }

@keyframes md-circular-progress-dash {
  0% {
    stroke-dashoffset: 187px; }
  50% {
    stroke-dashoffset: 46.75px; }
  100% {
    stroke-dashoffset: 187px; } }

.md-progress--linear {
  background: #019ee1;
  height: 3px;
  overflow: hidden;
  position: relative;
  width: 100%; }

.md-progress--linear-active {
  background: #019ee1; }

.md-progress--linear-determinate {
  height: 100%;
  position: absolute;
  z-index: 1; }

.md-progress--linear-indeterminate::before, .md-progress--linear-indeterminate::after {
  background: inherit;
  bottom: 0;
  content: '';
  position: absolute;
  top: 0;
  will-change: left, right;
  z-index: 1; }

.md-progress--linear-indeterminate::before {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-linear-indeterminate;
          animation-name: md-linear-indeterminate;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.md-progress--linear-indeterminate::after {
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-linear-indeterminate-short;
          animation-name: md-linear-indeterminate-short;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-progress--linear-query::before, .md-progress--linear-query::after {
  animation-direction: reverse; }

@-webkit-keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@-webkit-keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

.md-select-field {
  cursor: inherit;
  display: flex;
  flex-wrap: wrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-select-field .md-divider {
    width: 100%; }
  .md-select-field__toggle {
    position: relative; }

.md-select-field--btn.md-select-field--btn {
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 16px;
  padding-top: 16px; }

.md-select-field--text-field {
  pointer-events: none; }

.md-drop-enter {
  -webkit-transform: translate3d(0, -6px, 0);
          transform: translate3d(0, -6px, 0); }
  .md-drop-enter.md-drop-enter-active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

@media screen and (min-width: 320px) {
  .md-select-field--btn {
    height: 48px; }
  .md-select-field--text-field {
    height: 18px; } }

@media screen and (min-width: 1025px) {
  .md-select-field--btn {
    height: 40px; }
  .md-select-field--text-field {
    height: 15px; } }

.md-selection-control-container--inline {
  display: inline-block; }

.md-selection-control-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0; }

.md-selection-control-label {
  align-items: center;
  display: inline-flex; }

.md-selection-control-group {
  border: 0; }

.md-switch-container {
  align-items: center;
  display: flex;
  height: 48px; }
  .md-switch-container.md-selection-control-container--inline {
    display: inline-flex; }

.md-switch-track {
  border-radius: 8px;
  flex-shrink: 0;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  width: 40px; }

.md-switch-track--on {
  background: rgba(1, 158, 225, 0.5); }

.md-switch-track--off {
  background: rgba(0, 0, 0, 0.38); }

.md-switch-track--disabled {
  background: rgba(0, 0, 0, 0.12); }

.md-switch-thumb {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: '';
  display: block;
  height: 24px;
  position: absolute;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: background, transform;
  transition-property: background, transform, -webkit-transform;
  width: 24px; }

.md-switch-thumb--on {
  background: #019ee1;
  -webkit-transform: translate3d(20px, -4px, 0);
          transform: translate3d(20px, -4px, 0); }

.md-switch-thumb--off {
  background: #fafafa;
  -webkit-transform: translate3d(-4px, -4px, 0);
          transform: translate3d(-4px, -4px, 0); }

.md-switch-thumb--disabled {
  background: #bdbdbd; }

@media screen and (min-width: 320px) {
  .md-selection-control-label {
    font-size: 16px; } }

@media screen and (min-width: 1025px) {
  .md-selection-control-label {
    font-size: 13px; }
  .md-selection-control-container {
    height: 40px; } }

.md-slider-container {
  display: flex;
  flex-wrap: wrap;
  text-align: left; }

.md-slider-label {
  display: block;
  width: 100%; }

.md-slider-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0; }

.md-slider-track {
  background: rgba(0, 0, 0, 0.26);
  content: '';
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  height: 2px;
  margin-bottom: 23px;
  margin-top: 23px;
  position: relative; }

.md-slider-track-fill {
  background: #0d112b;
  border: 0;
  height: 2px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: width;
  transition-property: width; }

.md-slider-track-fill--dragging {
  -webkit-transition-property: none;
  transition-property: none; }

.md-slider-thumb {
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 14px;
  position: absolute;
  top: -6px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: left, background, -webkit-transform;
  transition-property: left, background, -webkit-transform;
  transition-property: left, transform, background;
  transition-property: left, transform, background, -webkit-transform;
  width: 14px;
  z-index: 5; }
  .md-slider-thumb:focus {
    outline-style: none; }

.md-slider-thumb--on {
  background: #0d112b; }

.md-slider-thumb--active {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  -webkit-transform-origin: center;
          transform-origin: center; }

.md-slider-thumb--dragging {
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: transform, background;
  transition-property: transform, background, -webkit-transform; }

.md-slider-thumb--disabled {
  background: rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(0.75);
          transform: scale(0.75); }

.md-slider-thumb--continuous-off {
  background: #fff;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.26);
  position: relative; }

.md-slider-thumb--mask {
  -webkit-transform: scale(1);
          transform: scale(1); }

.md-slider-thumb--mask-inked {
  background: rgba(13, 17, 43, 0.15);
  -webkit-transform: scale(3.5);
          transform: scale(3.5);
  z-index: 4; }

.md-slider-thumb--mask-disabled {
  background: #fff;
  border-radius: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 4; }

.md-slider-thumb--discrete::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: 10px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: border-top-color, -webkit-transform;
  transition-property: border-top-color, -webkit-transform;
  transition-property: border-top-color, transform;
  transition-property: border-top-color, transform, -webkit-transform;
  width: 0; }

.md-slider-thumb--discrete-active {
  top: 0;
  -webkit-transform: scale(2) translate3d(0, -18px, 0);
          transform: scale(2) translate3d(0, -18px, 0); }

.md-slider-thumb--discrete-on::after {
  border-top-color: #0d112b; }

.md-slider-thumb--discrete-off {
  background: #000; }

.md-slider-thumb--discrete-active-off {
  background: #bdbdbd; }
  .md-slider-thumb--discrete-active-off::after {
    border-top-color: #bdbdbd; }

.md-slider-thumb--discrete-mask-inked {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

.md-slider-thumb--discrete-mask-leaving {
  background: rgba(13, 17, 43, 0.3);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  z-index: 4; }

.md-slider-discrete-value {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 28px;
  justify-content: center;
  position: absolute;
  text-align: center;
  -webkit-transform: translate3d(-7px, -43px, 0);
          transform: translate3d(-7px, -43px, 0);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: left;
  transition-property: left;
  width: 28px;
  z-index: 6; }

.md-slider-discrete-tick {
  background: #000;
  content: '';
  height: 2px;
  position: absolute;
  top: 0px;
  z-index: 4; }

.md-slider-discrete-value--dragging {
  -webkit-transition-property: none;
  transition-property: none; }

.md-slider-editor {
  text-align: right; }

.md-slider-container .md-text-field-container {
  margin-left: 4px;
  padding-right: 0; }

@media screen and (min-width: 320px) {
  .md-slider-ind {
    font-size: 16px;
    margin-top: 14px; } }

@media screen and (min-width: 1025px) {
  .md-slider-ind {
    font-size: 13px;
    margin-top: 16px; } }

.md-slider-container .md-icon {
  margin-bottom: 12px;
  margin-top: 12px; }

.md-slider-ind {
  display: block;
  text-align: center; }

.md-slider-track--ind-left {
  margin-left: 16px; }

.md-slider-track--ind-right {
  margin-right: 16px; }

.md-subheader {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding-left: 16px;
  padding-right: 16px; }

.md-snackbar-container {
  position: absolute; }

.md-snackbar {
  align-items: center;
  background: #323232;
  bottom: 0;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: fixed;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  z-index: 20; }

.md-snackbar--multiline {
  height: 80px; }

.md-snackbar--toast {
  color: #fff;
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 24px; }

.md-snackbar-enter-active,
.md-snackbar-leave-active {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-snackbar-enter-active *,
  .md-snackbar-leave-active * {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: opacity;
    transition-property: opacity; }

.md-snackbar-enter.md-snackbar-enter-active {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-snackbar-leave.md-snackbar-leave-active {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-snackbar-enter *,
.md-snackbar-leave.md-snackbar-leave-active * {
  opacity: 0; }

.md-snackbar-enter.md-snackbar-enter-active *,
.md-snackbar-leave * {
  opacity: 1; }

.md-btn--snackbar {
  padding-left: 24px;
  padding-right: 24px; }

@media screen and (min-width: 320px) {
  .md-snackbar {
    left: 0;
    right: 0; }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .md-snackbar--action {
    padding-right: 0; }
  .md-btn--snackbar-floating {
    -webkit-transition: bottom 0.3s, background 0.15s, box-shadow 0.15s, color 0.15s;
    transition: bottom 0.3s, background 0.15s, box-shadow 0.15s, color 0.15s;
    will-change: bottom; }
  .md-btn--snackbar-floating-adjust {
    bottom: 64px; }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 96px; } }

@media screen and (min-width: 768px) {
  .md-snackbar {
    border-radius: 2px;
    left: 50%;
    max-width: 568px;
    min-width: 288px;
    right: auto;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    -webkit-transform: translate3d(-50%, 100%, 0);
            transform: translate3d(-50%, 100%, 0); }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-snackbar--action {
    padding-right: 24px; }
  .md-btn--snackbar-floating-adjust {
    bottom: 24px; }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 24px; } }

.md-tabs {
  display: flex;
  position: relative; }
  .md-tabs.md-background--primary .md-ink {
    background: rgba(255, 255, 255, 0.12); }
  .md-tabs--centered {
    justify-content: center; }
    .md-tabs--centered .md-tab {
      flex-grow: 0; }
  .md-tabs--pagination .md-tab {
    flex-grow: 0; }

.md-tab {
  align-items: center;
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: 48px;
  justify-content: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  .md-tab--multiline {
    font-size: 12px;
    padding-bottom: 12px; }
  .md-tab--icon {
    height: 72px;
    padding-bottom: 16px; }
  .md-tab--inactive {
    color: rgba(255, 255, 255, 0.7); }

.md-tab-indicator {
  background: #019ee1;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  position: absolute; }

.md-btn--tab-overflow {
  bottom: 2px;
  position: absolute; }
  .md-btn--tab-overflow--icon {
    bottom: 8px; }
  .md-btn--tab-overflow-left {
    left: 6px;
    z-index: 3; }
  .md-btn--tab-overflow-right {
    right: 12px; }

.md-menu--tab {
  margin-right: 100%; }
  .md-menu--tab .md-icon {
    color: inherit; }
  .md-menu--tab .md-tab {
    padding-top: 12px; }

.md-icon--tab {
  color: inherit;
  margin-bottom: 10px; }

.md-tab-toolbar {
  width: 100%; }

.md-tabs-fixed-container {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15; }

.md-tabs-content--offset {
  margin-top: 48px; }
  .md-tabs-content--offset-icon {
    margin-top: 72px; }

.md-tabs-content--offset-toolbar-prominent {
  margin-top: 176px; }
  .md-tabs-content--offset-toolbar-prominent-icon {
    margin-top: 200px; }

@media screen and (min-width: 320px) {
  .md-toolbar ~ .md-tabs {
    margin-top: -1px; }
  .md-tabs {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; }
  .md-tab {
    max-width: calc(100vw - 56px);
    min-width: 72px; }
  .md-tab-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px; } }

@media screen and (min-width: 320px) and (max-aspect-ratio: 13 / 9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 104px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 128px; } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 96px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 120px; } }

@media screen and (min-width: 768px) {
  .md-tabs-content--offset-toolbar {
    margin-top: 112px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 136px; } }

@media screen and (min-width: 1025px) {
  .md-toolbar ~ .md-tabs {
    margin-top: 0; }
  .md-tabs {
    overflow: visible; }
  .md-tab {
    max-width: 264px;
    min-width: 160px;
    padding-left: 24px;
    padding-right: 24px; }
  .md-tab-label {
    font-size: 13px;
    line-height: 13px; }
  .md-icon--tab {
    margin-bottom: 12px;
    margin-top: 2px; } }

.md-text-field-container {
  position: relative; }

.md-text-field-container--input {
  line-height: 1.15; }

.md-text-field-container--input-block {
  display: flex; }
  .md-text-field-container--input-block .md-text-field-message-container {
    flex-shrink: 0; }

.md-text-field-multiline-container,
.md-text-field-container--multiline {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: height;
  transition-property: height; }

.md-text-field-multiline-container {
  position: relative; }

.md-text-field-container--multiline {
  display: flex;
  flex-direction: column; }

.md-text-field {
  background: none;
  border: 0;
  line-height: inherit;
  padding: 0;
  width: 100%; }
  .md-text-field:placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:-moz-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field::-moz-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:focus {
    outline-style: none; }
  .md-text-field[type="search"] {
    -webkit-appearance: textfield; }
  .md-text-field:-webkit-autofill, .md-text-field:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px #fff inset; }
  .md-text-field--margin {
    margin-top: 13px; }
  .md-text-field[disabled]:placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]:-moz-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]::-moz-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }

.md-text-field--multiline {
  padding-bottom: 0;
  padding-top: 0;
  resize: none; }

.md-text-field--multiline-mask {
  overflow: hidden;
  position: absolute;
  visibility: hidden; }

.md-divider--text-field {
  margin-top: 7px;
  overflow: visible; }
  .md-divider--text-field::after {
    background: #0d112b;
    content: '';
    display: block;
    height: 2px;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: width;
    transition-property: width;
    width: 0; }

.md-divider--text-field-expanded::after {
  width: 100%; }

.md-divider--text-field-error,
.md-divider--text-field-error::after {
  background: #f44336; }

.md-divider--expand-from-left::after {
  left: 0; }

.md-divider--expand-from-center::after {
  margin: auto; }

.md-divider--expand-from-right::after {
  right: 0; }

.md-floating-label {
  cursor: text;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: font-size, color, -webkit-transform;
  transition-property: font-size, color, -webkit-transform;
  transition-property: transform, font-size, color;
  transition-property: transform, font-size, color, -webkit-transform;
  white-space: nowrap; }

.md-floating-label--floating {
  font-size: 12px; }

.md-floating-label--icon-offset {
  left: 40px; }
  @media screen and (min-width: 1025px) {
    .md-floating-label--icon-offset {
      left: 36px; } }

.md-text-field-message-container {
  display: flex;
  font-size: 12px;
  justify-content: space-between; }

.md-text-field-message-container--count-only {
  justify-content: flex-end; }

.md-text-field-message-container--left-icon-offset {
  padding-left: 40px; }

.md-text-field-message-container--right-icon-offset {
  padding-right: 40px; }

.md-text-field-message {
  color: inherit;
  flex-grow: 1;
  line-height: 1.42857;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  white-space: normal; }

.md-text-field-message--counter {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 16px; }

.md-text-field-message--inactive {
  opacity: 0; }

.md-text-field-message--active {
  opacity: 1; }

.md-text-field-divider-container {
  display: inline-block; }

.md-text-field-divider-container--grow {
  display: block;
  flex-grow: 1; }

.md-text-field-icon + .md-text-field-divider-container,
.md-text-field-divider-container + .md-text-field-icon {
  margin-left: 16px; }

.md-text-field-icon-container {
  align-items: flex-end;
  display: flex;
  width: 100%; }
  .md-text-field-container--input-block .md-text-field-icon-container {
    align-items: center; }

.md-text-field-inline-indicator {
  position: absolute;
  right: 0; }

.md-text-field--inline-indicator {
  width: calc(100% - 24px); }

.md-password-btn {
  background: transparent;
  border: 0;
  height: 24px;
  padding: 0;
  width: 24px; }
  .md-password-btn:not(.md-password-btn--focus):focus {
    outline-style: none; }
  .md-password-btn .md-icon {
    color: inherit; }

.md-password-btn--active::before {
  background: rgba(0, 0, 0, 0.54); }

.md-password-btn--invisible::before, .md-password-btn--invisible::after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 100%; }

.md-password-btn--invisible::before {
  -webkit-transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
          transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg); }

.md-password-btn--invisible::after {
  background: #fafafa;
  -webkit-transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
          transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg); }

@media screen and (min-width: 320px) {
  .md-text-field-container--input-block {
    padding-bottom: 20px;
    padding-top: 20px; }
  .md-text-field-container--multiline-block {
    margin-bottom: 20px;
    margin-top: 20px; }
  .md-text-field-container--padded-block {
    padding-left: 20px;
    padding-right: 20px; }
  .md-text-field {
    font-size: 16px; }
  .md-text-field--floating-margin {
    margin-top: 37px; }
  .md-divider--text-field {
    margin-bottom: 8px; }
  .md-floating-label--inactive {
    -webkit-transform: translate3d(0, 39px, 0);
            transform: translate3d(0, 39px, 0); }
  .md-floating-label--inactive-sized {
    font-size: 16px; }
  .md-floating-label--floating {
    -webkit-transform: translate3d(0, 16px, 0);
            transform: translate3d(0, 16px, 0); }
  .md-text-field-icon--positioned {
    margin-bottom: 13.5px; }
  .md-text-field-inline-indicator {
    top: 9px; }
    .md-text-field-inline-indicator--floating {
      top: 34px; }
    .md-text-field-inline-indicator--block {
      top: 17px; }
  .md-floating-label--inactive-title {
    -webkit-transform: translate3d(0, 42px, 0);
            transform: translate3d(0, 42px, 0); } }

@media screen and (min-width: 1025px) {
  .md-text-field-container--input-block {
    padding-bottom: 16px;
    padding-top: 16px; }
  .md-text-field-container--multiline-block {
    margin-bottom: 16px;
    margin-top: 16px; }
  .md-text-field-container--padded-block {
    padding-left: 16px;
    padding-right: 16px; }
  .md-text-field {
    font-size: 13px; }
  .md-text-field--floating-margin {
    margin-top: 33px; }
  .md-divider--text-field {
    margin-bottom: 4px; }
  .md-floating-label--inactive {
    -webkit-transform: translate3d(0, 33px, 0);
            transform: translate3d(0, 33px, 0); }
  .md-floating-label--inactive-sized {
    font-size: 13px; }
  .md-floating-label--floating {
    -webkit-transform: translate3d(0, 12px, 0);
            transform: translate3d(0, 12px, 0); }
  .md-text-field-icon--positioned {
    margin-bottom: 9.5px; }
  .md-password-btn.md-password-btn {
    height: 20px;
    width: 20px; }
  .md-text-field-message-container--left-icon-offset {
    padding-left: 36px; }
  .md-text-field-message-container--right-icon-offset {
    padding-right: 36px; }
  .md-text-field-inline-indicator {
    top: 9px; }
    .md-text-field-inline-indicator--floating {
      top: 30px; }
    .md-text-field-inline-indicator--block {
      top: 12px; }
  .md-floating-label--inactive-title {
    -webkit-transform: translate3d(0, 36px, 0);
            transform: translate3d(0, 36px, 0); } }

.md-text-field--title {
  font-size: 34px; }

.md-floating-label--title {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s; }

.md-floating-label--inactive-title {
  font-size: 34px; }

@media screen and (min-width: 320px) {
  .md-toolbar-relative {
    margin-top: 56px; }
    .md-toolbar-relative--padding {
      padding-top: 56px; }
  .md-toolbar {
    height: 56px; }
  .md-btn--toolbar {
    margin-bottom: 4px;
    margin-top: 4px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 10px;
    margin-top: 10px; }
  .md-toolbar--action-left {
    margin-left: 4px; }
  .md-toolbar--action-right {
    margin-right: 4px; }
  .md-title--toolbar {
    line-height: 56px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 72px; }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 6px;
    margin-top: 6px; } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-toolbar-relative {
    margin-top: 48px; }
    .md-toolbar-relative--padding {
      padding-top: 48px; }
  .md-toolbar {
    height: 48px; }
  .md-toolbar--action-left {
    margin-left: 4px; }
  .md-toolbar--action-right {
    margin-right: 4px; }
  .md-title--toolbar {
    line-height: 48px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 72px; } }

@media screen and (min-width: 768px) {
  .md-toolbar-relative {
    margin-top: 64px; }
    .md-toolbar-relative--padding {
      padding-top: 64px; }
  .md-toolbar {
    height: 64px; }
  .md-btn--toolbar {
    margin-bottom: 8px;
    margin-top: 8px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 14px;
    margin-top: 14px; }
  .md-toolbar--action-left {
    margin-left: 12px; }
  .md-toolbar--action-right {
    margin-right: 12px; }
  .md-title--toolbar {
    line-height: 64px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 80px; }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 10px;
    margin-top: 10px; } }

@media screen and (min-width: 768px) and (min-aspect-ratio: 13 / 9) {
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 12px;
    margin-top: 12px; } }

@media screen and (min-width: 1025px) {
  .md-toolbar--action-left {
    margin-left: 14px; }
  .md-toolbar--action-right {
    margin-right: 14px; }
  .md-title--toolbar {
    margin-left: 26px; }
    .md-title--toolbar-offset {
      margin-left: 80px; }
  .md-btn--toolbar {
    margin-bottom: 12px;
    margin-top: 12px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 16px;
    margin-top: 16px; } }

.md-toolbar {
  display: flex; }
  .md-toolbar--fixed {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 15; }
  .md-toolbar--inset {
    margin: 8px;
    max-width: calc(100vw - 16px); }
  .md-toolbar--text-white {
    color: #fff; }
    .md-toolbar--text-white .md-btn, .md-toolbar--text-white .md-icon, .md-toolbar--text-white .md-title--toolbar, .md-toolbar--text-white .md-text-field, .md-toolbar--text-white .md-select-field {
      color: inherit; }
    .md-toolbar--text-white :placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white :-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white ::-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
  .md-toolbar--themed {
    background: #f5f5f5; }
    .md-toolbar--themed .md-title--toolbar {
      color: rgba(0, 0, 0, 0.87); }
  .md-toolbar--prominent {
    height: 128px; }

.md-title--toolbar {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-title--toolbar-prominent {
    position: absolute;
    -webkit-transform: translate3d(0, 64px, 0);
            transform: translate3d(0, 64px, 0); }

.md-toolbar-relative--prominent {
  margin-top: 128px; }
  .md-toolbar-relative--prominent-padding {
    padding-top: 128px; }

.md-toolbar .md-text-field-container {
  padding-bottom: 0;
  padding-top: 0; }

.md-toolbar .md-text-field--toolbar {
  font-size: 20px; }

.md-toolbar .md-autocomplete-container,
.md-toolbar .md-autocomplete {
  height: 100%; }

@media screen and (min-width: 320px) {
  .md-tooltip {
    font-size: 14px;
    padding: 9px 16px; }
    .md-tooltip--top {
      top: -24px; }
    .md-tooltip--right {
      right: -24px; }
    .md-tooltip--bottom {
      bottom: -24px; }
    .md-tooltip--left {
      left: -24px; } }

@media screen and (min-width: 1025px) {
  .md-tooltip {
    font-size: 10px;
    padding: 6px 8px; }
    .md-tooltip--top {
      top: -14px; }
    .md-tooltip--right {
      right: -14px; }
    .md-tooltip--bottom {
      bottom: -14px; }
    .md-tooltip--left {
      left: -14px; } }

.md-tooltip-container {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  z-index: 1; }

.md-tooltip {
  background: #616161;
  color: #fff;
  display: block;
  font-weight: 500;
  opacity: 0.9;
  outline-style: none;
  position: absolute;
  white-space: nowrap; }
  .md-tooltip--active {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform; }
  .md-tooltip--enter, .md-tooltip--leave-active {
    opacity: 0; }
  .md-tooltip--enter-active {
    opacity: 0.9;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  .md-tooltip--leave-active {
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .md-tooltip--horizontal {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-tooltip--vertical {
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0); }
  .md-tooltip--top-active {
    -webkit-transform: translate3d(-50%, -100%, 0);
            transform: translate3d(-50%, -100%, 0); }
  .md-tooltip--right-active {
    -webkit-transform: translate3d(100%, -50%, 0);
            transform: translate3d(100%, -50%, 0); }
  .md-tooltip--bottom-active {
    -webkit-transform: translate3d(-50%, 100%, 0);
            transform: translate3d(-50%, 100%, 0); }
  .md-tooltip--left-active {
    -webkit-transform: translate3d(-100%, -50%, 0);
            transform: translate3d(-100%, -50%, 0); }

.md-text {
  color: rgba(0, 0, 0, 0.87); }
  .md-text--secondary {
    color: rgba(0, 0, 0, 0.54); }
  .md-text--disabled {
    color: rgba(0, 0, 0, 0.38); }
  .md-text--theme-primary {
    color: #0d112b; }
  .md-text--theme-secondary {
    color: #019ee1; }
  .md-text--error {
    color: #f44336; }
  .md-text--inherit.md-text--inherit {
    color: inherit; }

.md-ink--primary .md-ink {
  background: rgba(13, 17, 43, 0.26); }

.md-ink--secondary .md-ink {
  background: rgba(1, 158, 225, 0.26); }

.md-background {
  background: #fafafa; }
  .md-background--card {
    background: #fff; }
  .md-background--primary {
    background: #0d112b;
    color: #fff; }
  .md-background--secondary {
    background: #019ee1;
    color: #fff; }

@media screen and (min-width: 1025px) {
  .md-background--primary-hover:hover {
    background: rgba(13, 17, 43, 0.9); }
  .md-background--secondary-hover:hover {
    background: rgba(1, 158, 225, 0.9); } }

.custom-theme .md-btn--color-primary-active {
  background: rgba(96, 125, 139, 0.12); }

.custom-theme .md-btn--color-secondary-active {
  background: rgba(213, 0, 0, 0.12); }

.custom-theme .md-card-title--contextual {
  background: #ffebee; }

.custom-theme .md-card-title--title-contextual {
  color: #d50000; }

.custom-theme .md-picker-header {
  background: #607d8b; }

.custom-theme .md-calendar-date--btn::after {
  background: #607d8b; }

.custom-theme .md-clock-hand::before, .custom-theme .md-clock-hand::after {
  background: #607d8b; }

.custom-theme .md-circular-progress-path {
  stroke: #d50000; }

.custom-theme .md-slider-thumb--on,
.custom-theme .md-slider-track-fill {
  background: #607d8b; }

.custom-theme .md-slider-thumb--discrete-on::after {
  border-top-color: #607d8b; }

.custom-theme .md-tab-indicator {
  background: #d50000; }

.custom-theme .md-divider--text-field:not(.md-divider--text-field-error)::after {
  background: #607d8b; }

.custom-theme .md-text--theme-primary {
  color: #607d8b; }

.custom-theme .md-ink--primary .md-ink {
  background: rgba(96, 125, 139, 0.26); }

.custom-theme .md-background--primary {
  background: #607d8b; }

.custom-theme .md-text--theme-secondary {
  color: #d50000; }

.custom-theme .md-ink--secondary .md-ink {
  background: rgba(213, 0, 0, 0.26); }

.custom-theme .md-background--secondary {
  background: #d50000; }

@media screen and (min-width: 1025px) {
  .custom-theme .md-background--primary-hover:hover {
    background: rgba(96, 125, 139, 0.9); }
  .custom-theme .md-background--secondary-hover:hover {
    background: rgba(213, 0, 0, 0.9); } }

.fa,
.fab,
.fal,
.far,
.fas {
  font-size: 21px !important; }

.margin-top {
  margin-top: 50px; }

.toolbar-height {
  height: 64px; }

.custom-success-text {
  color: #43a047; }

.custom-error-text {
  color: #ef5350; }

.MyPaginContainer {
  display: flex !important;
  justify-content: center !important; }

.ag-grid-custom-link {
  color: black !important;
  cursor: pointer !important;
  text-decoration: underline !important; }

.ag-grid-custom-link:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline; }

.ag-grid-dropdown {
  background-color: #019ee1 !important;
  color: white;
  height: 32px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
  border-radius: 2px;
  font-weight: 500;
  padding: 8px 16px;
  text-transform: uppercase;
  border: none;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ''; }

.ag-grid-dropdown > option {
  background-color: white;
  color: black !important; }

.ag-paging-panel {
  align-items: center;
  display: flex;
  justify-content: center; }

.ag-floating-filter-input:-moz-read-only {
  background-color: transparent !important; }

.ag-floating-filter-input:read-only {
  background-color: transparent !important; }

.ag-watermark,
.ag-opacity-zero,
.ag-hidden {
  display: none; }

.ag-theme-material .ag-root-wrapper-body .ag-checkbox-input-wrapper.ag-checked::after {
  color: #019ee1; }

.ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: #019ee1; }

.ag-theme-material .ag-toggle-button.ag-selected .ag-input-wrapper {
  background-color: #019ee1;
  border-color: #019ee1; }

.ag-theme-material .ag-toggle-button-input-wrapper.ag-checked::before {
  border-color: #019ee1; }

.flatfile-component {
  z-index: 1000 !important; }

.MuiLinearProgress-colorSecondary {
  background-color: #faa !important; }

.MuiLinearProgress-barColorSecondary {
  background-color: #f00 !important; }

.cell-wrap-text {
  font-size: 0.875rem !important; }

.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start;
  /* align horizontal */
  align-items: center; }

.only-bootstrap * {
  box-sizing: border-box; }

.only-bootstrap *:before,
.only-bootstrap *:after {
  box-sizing: border-box; }

.only-bootstrap html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.only-bootstrap body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff; }

.only-bootstrap input,
.only-bootstrap button,
.only-bootstrap select,
.only-bootstrap textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

.only-bootstrap a {
  color: #337ab7;
  text-decoration: none; }
  .only-bootstrap a:hover, .only-bootstrap a:focus {
    color: #23527c;
    text-decoration: underline; }
  .only-bootstrap a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

.only-bootstrap figure {
  margin: 0; }

.only-bootstrap img {
  vertical-align: middle; }

.only-bootstrap .img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.only-bootstrap .img-rounded {
  border-radius: 6px; }

.only-bootstrap .img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.only-bootstrap .img-circle {
  border-radius: 50%; }

.only-bootstrap hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee; }

.only-bootstrap .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.only-bootstrap .sr-only-focusable:active, .only-bootstrap .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

.only-bootstrap [role="button"] {
  cursor: pointer; }

.only-bootstrap h1, .only-bootstrap h2, .only-bootstrap h3, .only-bootstrap h4, .only-bootstrap h5, .only-bootstrap h6,
.only-bootstrap .h1, .only-bootstrap .h2, .only-bootstrap .h3, .only-bootstrap .h4, .only-bootstrap .h5, .only-bootstrap .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }
  .only-bootstrap h1 small,
  .only-bootstrap h1 .small, .only-bootstrap h2 small,
  .only-bootstrap h2 .small, .only-bootstrap h3 small,
  .only-bootstrap h3 .small, .only-bootstrap h4 small,
  .only-bootstrap h4 .small, .only-bootstrap h5 small,
  .only-bootstrap h5 .small, .only-bootstrap h6 small,
  .only-bootstrap h6 .small,
  .only-bootstrap .h1 small,
  .only-bootstrap .h1 .small, .only-bootstrap .h2 small,
  .only-bootstrap .h2 .small, .only-bootstrap .h3 small,
  .only-bootstrap .h3 .small, .only-bootstrap .h4 small,
  .only-bootstrap .h4 .small, .only-bootstrap .h5 small,
  .only-bootstrap .h5 .small, .only-bootstrap .h6 small,
  .only-bootstrap .h6 .small {
    font-weight: 400;
    line-height: 1;
    color: #777777; }

.only-bootstrap h1, .only-bootstrap .h1,
.only-bootstrap h2, .only-bootstrap .h2,
.only-bootstrap h3, .only-bootstrap .h3 {
  margin-top: 20px;
  margin-bottom: 10px; }
  .only-bootstrap h1 small,
  .only-bootstrap h1 .small, .only-bootstrap .h1 small,
  .only-bootstrap .h1 .small,
  .only-bootstrap h2 small,
  .only-bootstrap h2 .small, .only-bootstrap .h2 small,
  .only-bootstrap .h2 .small,
  .only-bootstrap h3 small,
  .only-bootstrap h3 .small, .only-bootstrap .h3 small,
  .only-bootstrap .h3 .small {
    font-size: 65%; }

.only-bootstrap h4, .only-bootstrap .h4,
.only-bootstrap h5, .only-bootstrap .h5,
.only-bootstrap h6, .only-bootstrap .h6 {
  margin-top: 10px;
  margin-bottom: 10px; }
  .only-bootstrap h4 small,
  .only-bootstrap h4 .small, .only-bootstrap .h4 small,
  .only-bootstrap .h4 .small,
  .only-bootstrap h5 small,
  .only-bootstrap h5 .small, .only-bootstrap .h5 small,
  .only-bootstrap .h5 .small,
  .only-bootstrap h6 small,
  .only-bootstrap h6 .small, .only-bootstrap .h6 small,
  .only-bootstrap .h6 .small {
    font-size: 75%; }

.only-bootstrap h1, .only-bootstrap .h1 {
  font-size: 36px; }

.only-bootstrap h2, .only-bootstrap .h2 {
  font-size: 30px; }

.only-bootstrap h3, .only-bootstrap .h3 {
  font-size: 24px; }

.only-bootstrap h4, .only-bootstrap .h4 {
  font-size: 18px; }

.only-bootstrap h5, .only-bootstrap .h5 {
  font-size: 14px; }

.only-bootstrap h6, .only-bootstrap .h6 {
  font-size: 12px; }

.only-bootstrap p {
  margin: 0 0 10px; }

.only-bootstrap .lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4; }
  @media (min-width: 768px) {
    .only-bootstrap .lead {
      font-size: 21px; } }

.only-bootstrap small,
.only-bootstrap .small {
  font-size: 85%; }

.only-bootstrap mark,
.only-bootstrap .mark {
  padding: .2em;
  background-color: #fcf8e3; }

.only-bootstrap .text-left {
  text-align: left; }

.only-bootstrap .text-right {
  text-align: right; }

.only-bootstrap .text-center {
  text-align: center; }

.only-bootstrap .text-justify {
  text-align: justify; }

.only-bootstrap .text-nowrap {
  white-space: nowrap; }

.only-bootstrap .text-lowercase {
  text-transform: lowercase; }

.only-bootstrap .text-uppercase, .only-bootstrap .initialism {
  text-transform: uppercase; }

.only-bootstrap .text-capitalize {
  text-transform: capitalize; }

.only-bootstrap .text-muted {
  color: #777777; }

.only-bootstrap .text-primary {
  color: #337ab7; }

.only-bootstrap a.text-primary:hover,
.only-bootstrap a.text-primary:focus {
  color: #286090; }

.only-bootstrap .text-success {
  color: #3c763d; }

.only-bootstrap a.text-success:hover,
.only-bootstrap a.text-success:focus {
  color: #2b542c; }

.only-bootstrap .text-info {
  color: #31708f; }

.only-bootstrap a.text-info:hover,
.only-bootstrap a.text-info:focus {
  color: #245269; }

.only-bootstrap .text-warning {
  color: #8a6d3b; }

.only-bootstrap a.text-warning:hover,
.only-bootstrap a.text-warning:focus {
  color: #66512c; }

.only-bootstrap .text-danger {
  color: #a94442; }

.only-bootstrap a.text-danger:hover,
.only-bootstrap a.text-danger:focus {
  color: #843534; }

.only-bootstrap .bg-primary {
  color: #fff; }

.only-bootstrap .bg-primary {
  background-color: #337ab7; }

.only-bootstrap a.bg-primary:hover,
.only-bootstrap a.bg-primary:focus {
  background-color: #286090; }

.only-bootstrap .bg-success {
  background-color: #dff0d8; }

.only-bootstrap a.bg-success:hover,
.only-bootstrap a.bg-success:focus {
  background-color: #c1e2b3; }

.only-bootstrap .bg-info {
  background-color: #d9edf7; }

.only-bootstrap a.bg-info:hover,
.only-bootstrap a.bg-info:focus {
  background-color: #afd9ee; }

.only-bootstrap .bg-warning {
  background-color: #fcf8e3; }

.only-bootstrap a.bg-warning:hover,
.only-bootstrap a.bg-warning:focus {
  background-color: #f7ecb5; }

.only-bootstrap .bg-danger {
  background-color: #f2dede; }

.only-bootstrap a.bg-danger:hover,
.only-bootstrap a.bg-danger:focus {
  background-color: #e4b9b9; }

.only-bootstrap .page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee; }

.only-bootstrap ul,
.only-bootstrap ol {
  margin-top: 0;
  margin-bottom: 10px; }
  .only-bootstrap ul ul,
  .only-bootstrap ul ol,
  .only-bootstrap ol ul,
  .only-bootstrap ol ol {
    margin-bottom: 0; }

.only-bootstrap .list-unstyled {
  padding-left: 0;
  list-style: none; }

.only-bootstrap .list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }
  .only-bootstrap .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px; }

.only-bootstrap dl {
  margin-top: 0;
  margin-bottom: 20px; }

.only-bootstrap dt,
.only-bootstrap dd {
  line-height: 1.42857; }

.only-bootstrap dt {
  font-weight: 700; }

.only-bootstrap dd {
  margin-left: 0; }

.only-bootstrap .dl-horizontal dd:before, .only-bootstrap .dl-horizontal dd:after {
  display: table;
  content: " "; }

.only-bootstrap .dl-horizontal dd:after {
  clear: both; }

@media (min-width: 768px) {
  .only-bootstrap .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .only-bootstrap .dl-horizontal dd {
    margin-left: 180px; } }

.only-bootstrap abbr[title],
.only-bootstrap abbr[data-original-title] {
  cursor: help; }

.only-bootstrap .initialism {
  font-size: 90%; }

.only-bootstrap blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee; }
  .only-bootstrap blockquote p:last-child,
  .only-bootstrap blockquote ul:last-child,
  .only-bootstrap blockquote ol:last-child {
    margin-bottom: 0; }
  .only-bootstrap blockquote footer,
  .only-bootstrap blockquote small,
  .only-bootstrap blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857;
    color: #777777; }
    .only-bootstrap blockquote footer:before,
    .only-bootstrap blockquote small:before,
    .only-bootstrap blockquote .small:before {
      content: "\2014 \00A0"; }

.only-bootstrap .blockquote-reverse,
.only-bootstrap blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eeeeee;
  border-left: 0; }
  .only-bootstrap .blockquote-reverse footer:before,
  .only-bootstrap .blockquote-reverse small:before,
  .only-bootstrap .blockquote-reverse .small:before,
  .only-bootstrap blockquote.pull-right footer:before,
  .only-bootstrap blockquote.pull-right small:before,
  .only-bootstrap blockquote.pull-right .small:before {
    content: ""; }
  .only-bootstrap .blockquote-reverse footer:after,
  .only-bootstrap .blockquote-reverse small:after,
  .only-bootstrap .blockquote-reverse .small:after,
  .only-bootstrap blockquote.pull-right footer:after,
  .only-bootstrap blockquote.pull-right small:after,
  .only-bootstrap blockquote.pull-right .small:after {
    content: "\00A0 \2014"; }

.only-bootstrap address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857; }

.only-bootstrap code,
.only-bootstrap kbd,
.only-bootstrap pre,
.only-bootstrap samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

.only-bootstrap code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

.only-bootstrap kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }
  .only-bootstrap kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

.only-bootstrap pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  color: #333333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }
  .only-bootstrap pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0; }

.only-bootstrap .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.only-bootstrap .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .only-bootstrap .container:before, .only-bootstrap .container:after {
    display: table;
    content: " "; }
  .only-bootstrap .container:after {
    clear: both; }
  @media (min-width: 768px) {
    .only-bootstrap .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .only-bootstrap .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .only-bootstrap .container {
      width: 1170px; } }

.only-bootstrap .container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .only-bootstrap .container-fluid:before, .only-bootstrap .container-fluid:after {
    display: table;
    content: " "; }
  .only-bootstrap .container-fluid:after {
    clear: both; }

.only-bootstrap .row {
  margin-right: -15px;
  margin-left: -15px; }
  .only-bootstrap .row:before, .only-bootstrap .row:after {
    display: table;
    content: " "; }
  .only-bootstrap .row:after {
    clear: both; }

.only-bootstrap .row-no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .only-bootstrap .row-no-gutters [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.only-bootstrap .col-xs-1, .only-bootstrap .col-sm-1, .only-bootstrap .col-md-1, .only-bootstrap .col-lg-1, .only-bootstrap .col-xs-2, .only-bootstrap .col-sm-2, .only-bootstrap .col-md-2, .only-bootstrap .col-lg-2, .only-bootstrap .col-xs-3, .only-bootstrap .col-sm-3, .only-bootstrap .col-md-3, .only-bootstrap .col-lg-3, .only-bootstrap .col-xs-4, .only-bootstrap .col-sm-4, .only-bootstrap .col-md-4, .only-bootstrap .col-lg-4, .only-bootstrap .col-xs-5, .only-bootstrap .col-sm-5, .only-bootstrap .col-md-5, .only-bootstrap .col-lg-5, .only-bootstrap .col-xs-6, .only-bootstrap .col-sm-6, .only-bootstrap .col-md-6, .only-bootstrap .col-lg-6, .only-bootstrap .col-xs-7, .only-bootstrap .col-sm-7, .only-bootstrap .col-md-7, .only-bootstrap .col-lg-7, .only-bootstrap .col-xs-8, .only-bootstrap .col-sm-8, .only-bootstrap .col-md-8, .only-bootstrap .col-lg-8, .only-bootstrap .col-xs-9, .only-bootstrap .col-sm-9, .only-bootstrap .col-md-9, .only-bootstrap .col-lg-9, .only-bootstrap .col-xs-10, .only-bootstrap .col-sm-10, .only-bootstrap .col-md-10, .only-bootstrap .col-lg-10, .only-bootstrap .col-xs-11, .only-bootstrap .col-sm-11, .only-bootstrap .col-md-11, .only-bootstrap .col-lg-11, .only-bootstrap .col-xs-12, .only-bootstrap .col-sm-12, .only-bootstrap .col-md-12, .only-bootstrap .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.only-bootstrap .col-xs-1, .only-bootstrap .col-xs-2, .only-bootstrap .col-xs-3, .only-bootstrap .col-xs-4, .only-bootstrap .col-xs-5, .only-bootstrap .col-xs-6, .only-bootstrap .col-xs-7, .only-bootstrap .col-xs-8, .only-bootstrap .col-xs-9, .only-bootstrap .col-xs-10, .only-bootstrap .col-xs-11, .only-bootstrap .col-xs-12 {
  float: left; }

.only-bootstrap .col-xs-1 {
  width: 8.33333%; }

.only-bootstrap .col-xs-2 {
  width: 16.66667%; }

.only-bootstrap .col-xs-3 {
  width: 25%; }

.only-bootstrap .col-xs-4 {
  width: 33.33333%; }

.only-bootstrap .col-xs-5 {
  width: 41.66667%; }

.only-bootstrap .col-xs-6 {
  width: 50%; }

.only-bootstrap .col-xs-7 {
  width: 58.33333%; }

.only-bootstrap .col-xs-8 {
  width: 66.66667%; }

.only-bootstrap .col-xs-9 {
  width: 75%; }

.only-bootstrap .col-xs-10 {
  width: 83.33333%; }

.only-bootstrap .col-xs-11 {
  width: 91.66667%; }

.only-bootstrap .col-xs-12 {
  width: 100%; }

.only-bootstrap .col-xs-pull-0 {
  right: auto; }

.only-bootstrap .col-xs-pull-1 {
  right: 8.33333%; }

.only-bootstrap .col-xs-pull-2 {
  right: 16.66667%; }

.only-bootstrap .col-xs-pull-3 {
  right: 25%; }

.only-bootstrap .col-xs-pull-4 {
  right: 33.33333%; }

.only-bootstrap .col-xs-pull-5 {
  right: 41.66667%; }

.only-bootstrap .col-xs-pull-6 {
  right: 50%; }

.only-bootstrap .col-xs-pull-7 {
  right: 58.33333%; }

.only-bootstrap .col-xs-pull-8 {
  right: 66.66667%; }

.only-bootstrap .col-xs-pull-9 {
  right: 75%; }

.only-bootstrap .col-xs-pull-10 {
  right: 83.33333%; }

.only-bootstrap .col-xs-pull-11 {
  right: 91.66667%; }

.only-bootstrap .col-xs-pull-12 {
  right: 100%; }

.only-bootstrap .col-xs-push-0 {
  left: auto; }

.only-bootstrap .col-xs-push-1 {
  left: 8.33333%; }

.only-bootstrap .col-xs-push-2 {
  left: 16.66667%; }

.only-bootstrap .col-xs-push-3 {
  left: 25%; }

.only-bootstrap .col-xs-push-4 {
  left: 33.33333%; }

.only-bootstrap .col-xs-push-5 {
  left: 41.66667%; }

.only-bootstrap .col-xs-push-6 {
  left: 50%; }

.only-bootstrap .col-xs-push-7 {
  left: 58.33333%; }

.only-bootstrap .col-xs-push-8 {
  left: 66.66667%; }

.only-bootstrap .col-xs-push-9 {
  left: 75%; }

.only-bootstrap .col-xs-push-10 {
  left: 83.33333%; }

.only-bootstrap .col-xs-push-11 {
  left: 91.66667%; }

.only-bootstrap .col-xs-push-12 {
  left: 100%; }

.only-bootstrap .col-xs-offset-0 {
  margin-left: 0%; }

.only-bootstrap .col-xs-offset-1 {
  margin-left: 8.33333%; }

.only-bootstrap .col-xs-offset-2 {
  margin-left: 16.66667%; }

.only-bootstrap .col-xs-offset-3 {
  margin-left: 25%; }

.only-bootstrap .col-xs-offset-4 {
  margin-left: 33.33333%; }

.only-bootstrap .col-xs-offset-5 {
  margin-left: 41.66667%; }

.only-bootstrap .col-xs-offset-6 {
  margin-left: 50%; }

.only-bootstrap .col-xs-offset-7 {
  margin-left: 58.33333%; }

.only-bootstrap .col-xs-offset-8 {
  margin-left: 66.66667%; }

.only-bootstrap .col-xs-offset-9 {
  margin-left: 75%; }

.only-bootstrap .col-xs-offset-10 {
  margin-left: 83.33333%; }

.only-bootstrap .col-xs-offset-11 {
  margin-left: 91.66667%; }

.only-bootstrap .col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .only-bootstrap .col-sm-1, .only-bootstrap .col-sm-2, .only-bootstrap .col-sm-3, .only-bootstrap .col-sm-4, .only-bootstrap .col-sm-5, .only-bootstrap .col-sm-6, .only-bootstrap .col-sm-7, .only-bootstrap .col-sm-8, .only-bootstrap .col-sm-9, .only-bootstrap .col-sm-10, .only-bootstrap .col-sm-11, .only-bootstrap .col-sm-12 {
    float: left; }
  .only-bootstrap .col-sm-1 {
    width: 8.33333%; }
  .only-bootstrap .col-sm-2 {
    width: 16.66667%; }
  .only-bootstrap .col-sm-3 {
    width: 25%; }
  .only-bootstrap .col-sm-4 {
    width: 33.33333%; }
  .only-bootstrap .col-sm-5 {
    width: 41.66667%; }
  .only-bootstrap .col-sm-6 {
    width: 50%; }
  .only-bootstrap .col-sm-7 {
    width: 58.33333%; }
  .only-bootstrap .col-sm-8 {
    width: 66.66667%; }
  .only-bootstrap .col-sm-9 {
    width: 75%; }
  .only-bootstrap .col-sm-10 {
    width: 83.33333%; }
  .only-bootstrap .col-sm-11 {
    width: 91.66667%; }
  .only-bootstrap .col-sm-12 {
    width: 100%; }
  .only-bootstrap .col-sm-pull-0 {
    right: auto; }
  .only-bootstrap .col-sm-pull-1 {
    right: 8.33333%; }
  .only-bootstrap .col-sm-pull-2 {
    right: 16.66667%; }
  .only-bootstrap .col-sm-pull-3 {
    right: 25%; }
  .only-bootstrap .col-sm-pull-4 {
    right: 33.33333%; }
  .only-bootstrap .col-sm-pull-5 {
    right: 41.66667%; }
  .only-bootstrap .col-sm-pull-6 {
    right: 50%; }
  .only-bootstrap .col-sm-pull-7 {
    right: 58.33333%; }
  .only-bootstrap .col-sm-pull-8 {
    right: 66.66667%; }
  .only-bootstrap .col-sm-pull-9 {
    right: 75%; }
  .only-bootstrap .col-sm-pull-10 {
    right: 83.33333%; }
  .only-bootstrap .col-sm-pull-11 {
    right: 91.66667%; }
  .only-bootstrap .col-sm-pull-12 {
    right: 100%; }
  .only-bootstrap .col-sm-push-0 {
    left: auto; }
  .only-bootstrap .col-sm-push-1 {
    left: 8.33333%; }
  .only-bootstrap .col-sm-push-2 {
    left: 16.66667%; }
  .only-bootstrap .col-sm-push-3 {
    left: 25%; }
  .only-bootstrap .col-sm-push-4 {
    left: 33.33333%; }
  .only-bootstrap .col-sm-push-5 {
    left: 41.66667%; }
  .only-bootstrap .col-sm-push-6 {
    left: 50%; }
  .only-bootstrap .col-sm-push-7 {
    left: 58.33333%; }
  .only-bootstrap .col-sm-push-8 {
    left: 66.66667%; }
  .only-bootstrap .col-sm-push-9 {
    left: 75%; }
  .only-bootstrap .col-sm-push-10 {
    left: 83.33333%; }
  .only-bootstrap .col-sm-push-11 {
    left: 91.66667%; }
  .only-bootstrap .col-sm-push-12 {
    left: 100%; }
  .only-bootstrap .col-sm-offset-0 {
    margin-left: 0%; }
  .only-bootstrap .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .only-bootstrap .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .only-bootstrap .col-sm-offset-3 {
    margin-left: 25%; }
  .only-bootstrap .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .only-bootstrap .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .only-bootstrap .col-sm-offset-6 {
    margin-left: 50%; }
  .only-bootstrap .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .only-bootstrap .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .only-bootstrap .col-sm-offset-9 {
    margin-left: 75%; }
  .only-bootstrap .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .only-bootstrap .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .only-bootstrap .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .only-bootstrap .col-md-1, .only-bootstrap .col-md-2, .only-bootstrap .col-md-3, .only-bootstrap .col-md-4, .only-bootstrap .col-md-5, .only-bootstrap .col-md-6, .only-bootstrap .col-md-7, .only-bootstrap .col-md-8, .only-bootstrap .col-md-9, .only-bootstrap .col-md-10, .only-bootstrap .col-md-11, .only-bootstrap .col-md-12 {
    float: left; }
  .only-bootstrap .col-md-1 {
    width: 8.33333%; }
  .only-bootstrap .col-md-2 {
    width: 16.66667%; }
  .only-bootstrap .col-md-3 {
    width: 25%; }
  .only-bootstrap .col-md-4 {
    width: 33.33333%; }
  .only-bootstrap .col-md-5 {
    width: 41.66667%; }
  .only-bootstrap .col-md-6 {
    width: 50%; }
  .only-bootstrap .col-md-7 {
    width: 58.33333%; }
  .only-bootstrap .col-md-8 {
    width: 66.66667%; }
  .only-bootstrap .col-md-9 {
    width: 75%; }
  .only-bootstrap .col-md-10 {
    width: 83.33333%; }
  .only-bootstrap .col-md-11 {
    width: 91.66667%; }
  .only-bootstrap .col-md-12 {
    width: 100%; }
  .only-bootstrap .col-md-pull-0 {
    right: auto; }
  .only-bootstrap .col-md-pull-1 {
    right: 8.33333%; }
  .only-bootstrap .col-md-pull-2 {
    right: 16.66667%; }
  .only-bootstrap .col-md-pull-3 {
    right: 25%; }
  .only-bootstrap .col-md-pull-4 {
    right: 33.33333%; }
  .only-bootstrap .col-md-pull-5 {
    right: 41.66667%; }
  .only-bootstrap .col-md-pull-6 {
    right: 50%; }
  .only-bootstrap .col-md-pull-7 {
    right: 58.33333%; }
  .only-bootstrap .col-md-pull-8 {
    right: 66.66667%; }
  .only-bootstrap .col-md-pull-9 {
    right: 75%; }
  .only-bootstrap .col-md-pull-10 {
    right: 83.33333%; }
  .only-bootstrap .col-md-pull-11 {
    right: 91.66667%; }
  .only-bootstrap .col-md-pull-12 {
    right: 100%; }
  .only-bootstrap .col-md-push-0 {
    left: auto; }
  .only-bootstrap .col-md-push-1 {
    left: 8.33333%; }
  .only-bootstrap .col-md-push-2 {
    left: 16.66667%; }
  .only-bootstrap .col-md-push-3 {
    left: 25%; }
  .only-bootstrap .col-md-push-4 {
    left: 33.33333%; }
  .only-bootstrap .col-md-push-5 {
    left: 41.66667%; }
  .only-bootstrap .col-md-push-6 {
    left: 50%; }
  .only-bootstrap .col-md-push-7 {
    left: 58.33333%; }
  .only-bootstrap .col-md-push-8 {
    left: 66.66667%; }
  .only-bootstrap .col-md-push-9 {
    left: 75%; }
  .only-bootstrap .col-md-push-10 {
    left: 83.33333%; }
  .only-bootstrap .col-md-push-11 {
    left: 91.66667%; }
  .only-bootstrap .col-md-push-12 {
    left: 100%; }
  .only-bootstrap .col-md-offset-0 {
    margin-left: 0%; }
  .only-bootstrap .col-md-offset-1 {
    margin-left: 8.33333%; }
  .only-bootstrap .col-md-offset-2 {
    margin-left: 16.66667%; }
  .only-bootstrap .col-md-offset-3 {
    margin-left: 25%; }
  .only-bootstrap .col-md-offset-4 {
    margin-left: 33.33333%; }
  .only-bootstrap .col-md-offset-5 {
    margin-left: 41.66667%; }
  .only-bootstrap .col-md-offset-6 {
    margin-left: 50%; }
  .only-bootstrap .col-md-offset-7 {
    margin-left: 58.33333%; }
  .only-bootstrap .col-md-offset-8 {
    margin-left: 66.66667%; }
  .only-bootstrap .col-md-offset-9 {
    margin-left: 75%; }
  .only-bootstrap .col-md-offset-10 {
    margin-left: 83.33333%; }
  .only-bootstrap .col-md-offset-11 {
    margin-left: 91.66667%; }
  .only-bootstrap .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .only-bootstrap .col-lg-1, .only-bootstrap .col-lg-2, .only-bootstrap .col-lg-3, .only-bootstrap .col-lg-4, .only-bootstrap .col-lg-5, .only-bootstrap .col-lg-6, .only-bootstrap .col-lg-7, .only-bootstrap .col-lg-8, .only-bootstrap .col-lg-9, .only-bootstrap .col-lg-10, .only-bootstrap .col-lg-11, .only-bootstrap .col-lg-12 {
    float: left; }
  .only-bootstrap .col-lg-1 {
    width: 8.33333%; }
  .only-bootstrap .col-lg-2 {
    width: 16.66667%; }
  .only-bootstrap .col-lg-3 {
    width: 25%; }
  .only-bootstrap .col-lg-4 {
    width: 33.33333%; }
  .only-bootstrap .col-lg-5 {
    width: 41.66667%; }
  .only-bootstrap .col-lg-6 {
    width: 50%; }
  .only-bootstrap .col-lg-7 {
    width: 58.33333%; }
  .only-bootstrap .col-lg-8 {
    width: 66.66667%; }
  .only-bootstrap .col-lg-9 {
    width: 75%; }
  .only-bootstrap .col-lg-10 {
    width: 83.33333%; }
  .only-bootstrap .col-lg-11 {
    width: 91.66667%; }
  .only-bootstrap .col-lg-12 {
    width: 100%; }
  .only-bootstrap .col-lg-pull-0 {
    right: auto; }
  .only-bootstrap .col-lg-pull-1 {
    right: 8.33333%; }
  .only-bootstrap .col-lg-pull-2 {
    right: 16.66667%; }
  .only-bootstrap .col-lg-pull-3 {
    right: 25%; }
  .only-bootstrap .col-lg-pull-4 {
    right: 33.33333%; }
  .only-bootstrap .col-lg-pull-5 {
    right: 41.66667%; }
  .only-bootstrap .col-lg-pull-6 {
    right: 50%; }
  .only-bootstrap .col-lg-pull-7 {
    right: 58.33333%; }
  .only-bootstrap .col-lg-pull-8 {
    right: 66.66667%; }
  .only-bootstrap .col-lg-pull-9 {
    right: 75%; }
  .only-bootstrap .col-lg-pull-10 {
    right: 83.33333%; }
  .only-bootstrap .col-lg-pull-11 {
    right: 91.66667%; }
  .only-bootstrap .col-lg-pull-12 {
    right: 100%; }
  .only-bootstrap .col-lg-push-0 {
    left: auto; }
  .only-bootstrap .col-lg-push-1 {
    left: 8.33333%; }
  .only-bootstrap .col-lg-push-2 {
    left: 16.66667%; }
  .only-bootstrap .col-lg-push-3 {
    left: 25%; }
  .only-bootstrap .col-lg-push-4 {
    left: 33.33333%; }
  .only-bootstrap .col-lg-push-5 {
    left: 41.66667%; }
  .only-bootstrap .col-lg-push-6 {
    left: 50%; }
  .only-bootstrap .col-lg-push-7 {
    left: 58.33333%; }
  .only-bootstrap .col-lg-push-8 {
    left: 66.66667%; }
  .only-bootstrap .col-lg-push-9 {
    left: 75%; }
  .only-bootstrap .col-lg-push-10 {
    left: 83.33333%; }
  .only-bootstrap .col-lg-push-11 {
    left: 91.66667%; }
  .only-bootstrap .col-lg-push-12 {
    left: 100%; }
  .only-bootstrap .col-lg-offset-0 {
    margin-left: 0%; }
  .only-bootstrap .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .only-bootstrap .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .only-bootstrap .col-lg-offset-3 {
    margin-left: 25%; }
  .only-bootstrap .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .only-bootstrap .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .only-bootstrap .col-lg-offset-6 {
    margin-left: 50%; }
  .only-bootstrap .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .only-bootstrap .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .only-bootstrap .col-lg-offset-9 {
    margin-left: 75%; }
  .only-bootstrap .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .only-bootstrap .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .only-bootstrap .col-lg-offset-12 {
    margin-left: 100%; } }

.only-bootstrap table {
  background-color: transparent; }
  .only-bootstrap table col[class*="col-"] {
    position: static;
    display: table-column;
    float: none; }
  .only-bootstrap table td[class*="col-"],
  .only-bootstrap table th[class*="col-"] {
    position: static;
    display: table-cell;
    float: none; }

.only-bootstrap caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

.only-bootstrap th {
  text-align: left; }

.only-bootstrap .table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px; }
  .only-bootstrap .table > thead > tr > th,
  .only-bootstrap .table > thead > tr > td,
  .only-bootstrap .table > tbody > tr > th,
  .only-bootstrap .table > tbody > tr > td,
  .only-bootstrap .table > tfoot > tr > th,
  .only-bootstrap .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .only-bootstrap .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .only-bootstrap .table > caption + thead > tr:first-child > th,
  .only-bootstrap .table > caption + thead > tr:first-child > td,
  .only-bootstrap .table > colgroup + thead > tr:first-child > th,
  .only-bootstrap .table > colgroup + thead > tr:first-child > td,
  .only-bootstrap .table > thead:first-child > tr:first-child > th,
  .only-bootstrap .table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  .only-bootstrap .table > tbody + tbody {
    border-top: 2px solid #ddd; }
  .only-bootstrap .table .table {
    background-color: #fff; }

.only-bootstrap .table-condensed > thead > tr > th,
.only-bootstrap .table-condensed > thead > tr > td,
.only-bootstrap .table-condensed > tbody > tr > th,
.only-bootstrap .table-condensed > tbody > tr > td,
.only-bootstrap .table-condensed > tfoot > tr > th,
.only-bootstrap .table-condensed > tfoot > tr > td {
  padding: 5px; }

.only-bootstrap .table-bordered {
  border: 1px solid #ddd; }
  .only-bootstrap .table-bordered > thead > tr > th,
  .only-bootstrap .table-bordered > thead > tr > td,
  .only-bootstrap .table-bordered > tbody > tr > th,
  .only-bootstrap .table-bordered > tbody > tr > td,
  .only-bootstrap .table-bordered > tfoot > tr > th,
  .only-bootstrap .table-bordered > tfoot > tr > td {
    border: 1px solid #ddd; }
  .only-bootstrap .table-bordered > thead > tr > th,
  .only-bootstrap .table-bordered > thead > tr > td {
    border-bottom-width: 2px; }

.only-bootstrap .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.only-bootstrap .table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

.only-bootstrap .table > thead > tr > td.active,
.only-bootstrap .table > thead > tr > th.active,
.only-bootstrap .table > thead > tr.active > td,
.only-bootstrap .table > thead > tr.active > th,
.only-bootstrap .table > tbody > tr > td.active,
.only-bootstrap .table > tbody > tr > th.active,
.only-bootstrap .table > tbody > tr.active > td,
.only-bootstrap .table > tbody > tr.active > th,
.only-bootstrap .table > tfoot > tr > td.active,
.only-bootstrap .table > tfoot > tr > th.active,
.only-bootstrap .table > tfoot > tr.active > td,
.only-bootstrap .table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.only-bootstrap .table-hover > tbody > tr > td.active:hover,
.only-bootstrap .table-hover > tbody > tr > th.active:hover,
.only-bootstrap .table-hover > tbody > tr.active:hover > td,
.only-bootstrap .table-hover > tbody > tr:hover > .active,
.only-bootstrap .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.only-bootstrap .table > thead > tr > td.success,
.only-bootstrap .table > thead > tr > th.success,
.only-bootstrap .table > thead > tr.success > td,
.only-bootstrap .table > thead > tr.success > th,
.only-bootstrap .table > tbody > tr > td.success,
.only-bootstrap .table > tbody > tr > th.success,
.only-bootstrap .table > tbody > tr.success > td,
.only-bootstrap .table > tbody > tr.success > th,
.only-bootstrap .table > tfoot > tr > td.success,
.only-bootstrap .table > tfoot > tr > th.success,
.only-bootstrap .table > tfoot > tr.success > td,
.only-bootstrap .table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.only-bootstrap .table-hover > tbody > tr > td.success:hover,
.only-bootstrap .table-hover > tbody > tr > th.success:hover,
.only-bootstrap .table-hover > tbody > tr.success:hover > td,
.only-bootstrap .table-hover > tbody > tr:hover > .success,
.only-bootstrap .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.only-bootstrap .table > thead > tr > td.info,
.only-bootstrap .table > thead > tr > th.info,
.only-bootstrap .table > thead > tr.info > td,
.only-bootstrap .table > thead > tr.info > th,
.only-bootstrap .table > tbody > tr > td.info,
.only-bootstrap .table > tbody > tr > th.info,
.only-bootstrap .table > tbody > tr.info > td,
.only-bootstrap .table > tbody > tr.info > th,
.only-bootstrap .table > tfoot > tr > td.info,
.only-bootstrap .table > tfoot > tr > th.info,
.only-bootstrap .table > tfoot > tr.info > td,
.only-bootstrap .table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.only-bootstrap .table-hover > tbody > tr > td.info:hover,
.only-bootstrap .table-hover > tbody > tr > th.info:hover,
.only-bootstrap .table-hover > tbody > tr.info:hover > td,
.only-bootstrap .table-hover > tbody > tr:hover > .info,
.only-bootstrap .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.only-bootstrap .table > thead > tr > td.warning,
.only-bootstrap .table > thead > tr > th.warning,
.only-bootstrap .table > thead > tr.warning > td,
.only-bootstrap .table > thead > tr.warning > th,
.only-bootstrap .table > tbody > tr > td.warning,
.only-bootstrap .table > tbody > tr > th.warning,
.only-bootstrap .table > tbody > tr.warning > td,
.only-bootstrap .table > tbody > tr.warning > th,
.only-bootstrap .table > tfoot > tr > td.warning,
.only-bootstrap .table > tfoot > tr > th.warning,
.only-bootstrap .table > tfoot > tr.warning > td,
.only-bootstrap .table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.only-bootstrap .table-hover > tbody > tr > td.warning:hover,
.only-bootstrap .table-hover > tbody > tr > th.warning:hover,
.only-bootstrap .table-hover > tbody > tr.warning:hover > td,
.only-bootstrap .table-hover > tbody > tr:hover > .warning,
.only-bootstrap .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.only-bootstrap .table > thead > tr > td.danger,
.only-bootstrap .table > thead > tr > th.danger,
.only-bootstrap .table > thead > tr.danger > td,
.only-bootstrap .table > thead > tr.danger > th,
.only-bootstrap .table > tbody > tr > td.danger,
.only-bootstrap .table > tbody > tr > th.danger,
.only-bootstrap .table > tbody > tr.danger > td,
.only-bootstrap .table > tbody > tr.danger > th,
.only-bootstrap .table > tfoot > tr > td.danger,
.only-bootstrap .table > tfoot > tr > th.danger,
.only-bootstrap .table > tfoot > tr.danger > td,
.only-bootstrap .table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.only-bootstrap .table-hover > tbody > tr > td.danger:hover,
.only-bootstrap .table-hover > tbody > tr > th.danger:hover,
.only-bootstrap .table-hover > tbody > tr.danger:hover > td,
.only-bootstrap .table-hover > tbody > tr:hover > .danger,
.only-bootstrap .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

.only-bootstrap .table-responsive {
  min-height: .01%;
  overflow-x: auto; }
  @media screen and (max-width: 767px) {
    .only-bootstrap .table-responsive {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd; }
      .only-bootstrap .table-responsive > .table {
        margin-bottom: 0; }
        .only-bootstrap .table-responsive > .table > thead > tr > th,
        .only-bootstrap .table-responsive > .table > thead > tr > td,
        .only-bootstrap .table-responsive > .table > tbody > tr > th,
        .only-bootstrap .table-responsive > .table > tbody > tr > td,
        .only-bootstrap .table-responsive > .table > tfoot > tr > th,
        .only-bootstrap .table-responsive > .table > tfoot > tr > td {
          white-space: nowrap; }
      .only-bootstrap .table-responsive > .table-bordered {
        border: 0; }
        .only-bootstrap .table-responsive > .table-bordered > thead > tr > th:first-child,
        .only-bootstrap .table-responsive > .table-bordered > thead > tr > td:first-child,
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr > th:first-child,
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr > td:first-child,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr > th:first-child,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr > td:first-child {
          border-left: 0; }
        .only-bootstrap .table-responsive > .table-bordered > thead > tr > th:last-child,
        .only-bootstrap .table-responsive > .table-bordered > thead > tr > td:last-child,
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr > th:last-child,
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr > td:last-child,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr > th:last-child,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr > td:last-child {
          border-right: 0; }
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr:last-child > th,
        .only-bootstrap .table-responsive > .table-bordered > tbody > tr:last-child > td,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr:last-child > th,
        .only-bootstrap .table-responsive > .table-bordered > tfoot > tr:last-child > td {
          border-bottom: 0; } }

.only-bootstrap fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.only-bootstrap legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

.only-bootstrap label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700; }

.only-bootstrap input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none; }

.only-bootstrap input[type="radio"],
.only-bootstrap input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }
  .only-bootstrap input[type="radio"][disabled], .only-bootstrap input[type="radio"].disabled,
  fieldset[disabled] .only-bootstrap input[type="radio"],
  .only-bootstrap input[type="checkbox"][disabled],
  .only-bootstrap input[type="checkbox"].disabled,
  fieldset[disabled]
  .only-bootstrap input[type="checkbox"] {
    cursor: not-allowed; }

.only-bootstrap input[type="file"] {
  display: block; }

.only-bootstrap input[type="range"] {
  display: block;
  width: 100%; }

.only-bootstrap select[multiple],
.only-bootstrap select[size] {
  height: auto; }

.only-bootstrap input[type="file"]:focus,
.only-bootstrap input[type="radio"]:focus,
.only-bootstrap input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

.only-bootstrap output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

.only-bootstrap .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .only-bootstrap .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .only-bootstrap .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .only-bootstrap .form-control:-ms-input-placeholder {
    color: #999; }
  .only-bootstrap .form-control::-webkit-input-placeholder {
    color: #999; }
  .only-bootstrap .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .only-bootstrap .form-control[disabled], .only-bootstrap .form-control[readonly],
  fieldset[disabled] .only-bootstrap .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .only-bootstrap .form-control[disabled],
  fieldset[disabled] .only-bootstrap .form-control {
    cursor: not-allowed; }

.only-bootstrap textarea.form-control {
  height: auto; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .only-bootstrap input[type="date"].form-control,
  .only-bootstrap input[type="time"].form-control,
  .only-bootstrap input[type="datetime-local"].form-control,
  .only-bootstrap input[type="month"].form-control {
    line-height: 34px; }
  .only-bootstrap input[type="date"].input-sm,
  .input-group-sm .only-bootstrap input[type="date"],
  .only-bootstrap input[type="time"].input-sm,
  .input-group-sm
  .only-bootstrap input[type="time"],
  .only-bootstrap input[type="datetime-local"].input-sm,
  .input-group-sm
  .only-bootstrap input[type="datetime-local"],
  .only-bootstrap input[type="month"].input-sm,
  .input-group-sm
  .only-bootstrap input[type="month"] {
    line-height: 30px; }
  .only-bootstrap input[type="date"].input-lg,
  .input-group-lg .only-bootstrap input[type="date"],
  .only-bootstrap input[type="time"].input-lg,
  .input-group-lg
  .only-bootstrap input[type="time"],
  .only-bootstrap input[type="datetime-local"].input-lg,
  .input-group-lg
  .only-bootstrap input[type="datetime-local"],
  .only-bootstrap input[type="month"].input-lg,
  .input-group-lg
  .only-bootstrap input[type="month"] {
    line-height: 46px; } }

.only-bootstrap .form-group {
  margin-bottom: 15px; }

.only-bootstrap .radio,
.only-bootstrap .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .only-bootstrap .radio.disabled label,
  fieldset[disabled] .only-bootstrap .radio label,
  .only-bootstrap .checkbox.disabled label,
  fieldset[disabled]
  .only-bootstrap .checkbox label {
    cursor: not-allowed; }
  .only-bootstrap .radio label,
  .only-bootstrap .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer; }

.only-bootstrap .radio input[type="radio"],
.only-bootstrap .radio-inline input[type="radio"],
.only-bootstrap .checkbox input[type="checkbox"],
.only-bootstrap .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px; }

.only-bootstrap .radio + .radio,
.only-bootstrap .checkbox + .checkbox {
  margin-top: -5px; }

.only-bootstrap .radio-inline,
.only-bootstrap .checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer; }
  .only-bootstrap .radio-inline.disabled,
  fieldset[disabled] .only-bootstrap .radio-inline,
  .only-bootstrap .checkbox-inline.disabled,
  fieldset[disabled]
  .only-bootstrap .checkbox-inline {
    cursor: not-allowed; }

.only-bootstrap .radio-inline + .radio-inline,
.only-bootstrap .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

.only-bootstrap .form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0; }
  .only-bootstrap .form-control-static.input-lg, .only-bootstrap .form-control-static.input-sm {
    padding-right: 0;
    padding-left: 0; }

.only-bootstrap .input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.only-bootstrap select.input-sm {
  height: 30px;
  line-height: 30px; }

.only-bootstrap textarea.input-sm,
.only-bootstrap select[multiple].input-sm {
  height: auto; }

.only-bootstrap .form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.only-bootstrap .form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.only-bootstrap .form-group-sm textarea.form-control,
.only-bootstrap .form-group-sm select[multiple].form-control {
  height: auto; }

.only-bootstrap .form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.only-bootstrap .input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.only-bootstrap select.input-lg {
  height: 46px;
  line-height: 46px; }

.only-bootstrap textarea.input-lg,
.only-bootstrap select[multiple].input-lg {
  height: auto; }

.only-bootstrap .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.only-bootstrap .form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.only-bootstrap .form-group-lg textarea.form-control,
.only-bootstrap .form-group-lg select[multiple].form-control {
  height: auto; }

.only-bootstrap .form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333; }

.only-bootstrap .has-feedback {
  position: relative; }
  .only-bootstrap .has-feedback .form-control {
    padding-right: 42.5px; }

.only-bootstrap .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.only-bootstrap .input-lg + .form-control-feedback,
.only-bootstrap .input-group-lg + .form-control-feedback,
.only-bootstrap .form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.only-bootstrap .input-sm + .form-control-feedback,
.only-bootstrap .input-group-sm + .form-control-feedback,
.only-bootstrap .form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.only-bootstrap .has-success .help-block,
.only-bootstrap .has-success .control-label,
.only-bootstrap .has-success .radio,
.only-bootstrap .has-success .checkbox,
.only-bootstrap .has-success .radio-inline,
.only-bootstrap .has-success .checkbox-inline,
.only-bootstrap .has-success.radio label,
.only-bootstrap .has-success.checkbox label,
.only-bootstrap .has-success.radio-inline label,
.only-bootstrap .has-success.checkbox-inline label {
  color: #3c763d; }

.only-bootstrap .has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .only-bootstrap .has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.only-bootstrap .has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d; }

.only-bootstrap .has-success .form-control-feedback {
  color: #3c763d; }

.only-bootstrap .has-warning .help-block,
.only-bootstrap .has-warning .control-label,
.only-bootstrap .has-warning .radio,
.only-bootstrap .has-warning .checkbox,
.only-bootstrap .has-warning .radio-inline,
.only-bootstrap .has-warning .checkbox-inline,
.only-bootstrap .has-warning.radio label,
.only-bootstrap .has-warning.checkbox label,
.only-bootstrap .has-warning.radio-inline label,
.only-bootstrap .has-warning.checkbox-inline label {
  color: #8a6d3b; }

.only-bootstrap .has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .only-bootstrap .has-warning .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.only-bootstrap .has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b; }

.only-bootstrap .has-warning .form-control-feedback {
  color: #8a6d3b; }

.only-bootstrap .has-error .help-block,
.only-bootstrap .has-error .control-label,
.only-bootstrap .has-error .radio,
.only-bootstrap .has-error .checkbox,
.only-bootstrap .has-error .radio-inline,
.only-bootstrap .has-error .checkbox-inline,
.only-bootstrap .has-error.radio label,
.only-bootstrap .has-error.checkbox label,
.only-bootstrap .has-error.radio-inline label,
.only-bootstrap .has-error.checkbox-inline label {
  color: #a94442; }

.only-bootstrap .has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .only-bootstrap .has-error .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.only-bootstrap .has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442; }

.only-bootstrap .has-error .form-control-feedback {
  color: #a94442; }

.only-bootstrap .has-feedback label ~ .form-control-feedback {
  top: 25px; }

.only-bootstrap .has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.only-bootstrap .help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373; }

@media (min-width: 768px) {
  .only-bootstrap .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .only-bootstrap .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .only-bootstrap .form-inline .form-control-static {
    display: inline-block; }
  .only-bootstrap .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .only-bootstrap .form-inline .input-group .input-group-addon,
    .only-bootstrap .form-inline .input-group .input-group-btn,
    .only-bootstrap .form-inline .input-group .form-control {
      width: auto; }
  .only-bootstrap .form-inline .input-group > .form-control {
    width: 100%; }
  .only-bootstrap .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .only-bootstrap .form-inline .radio,
  .only-bootstrap .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .only-bootstrap .form-inline .radio label,
    .only-bootstrap .form-inline .checkbox label {
      padding-left: 0; }
  .only-bootstrap .form-inline .radio input[type="radio"],
  .only-bootstrap .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .only-bootstrap .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.only-bootstrap .form-horizontal .radio,
.only-bootstrap .form-horizontal .checkbox,
.only-bootstrap .form-horizontal .radio-inline,
.only-bootstrap .form-horizontal .checkbox-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0; }

.only-bootstrap .form-horizontal .radio,
.only-bootstrap .form-horizontal .checkbox {
  min-height: 27px; }

.only-bootstrap .form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px; }
  .only-bootstrap .form-horizontal .form-group:before, .only-bootstrap .form-horizontal .form-group:after {
    display: table;
    content: " "; }
  .only-bootstrap .form-horizontal .form-group:after {
    clear: both; }

@media (min-width: 768px) {
  .only-bootstrap .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right; } }

.only-bootstrap .form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .only-bootstrap .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .only-bootstrap .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.only-bootstrap .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .only-bootstrap .btn:focus, .only-bootstrap .btn.focus, .only-bootstrap .btn:active:focus, .only-bootstrap .btn:active.focus, .only-bootstrap .btn.active:focus, .only-bootstrap .btn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .only-bootstrap .btn:hover, .only-bootstrap .btn:focus, .only-bootstrap .btn.focus {
    color: #333;
    text-decoration: none; }
  .only-bootstrap .btn:active, .only-bootstrap .btn.active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .only-bootstrap .btn.disabled, .only-bootstrap .btn[disabled],
  fieldset[disabled] .only-bootstrap .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    box-shadow: none; }

.only-bootstrap a.btn.disabled,
fieldset[disabled] .only-bootstrap a.btn {
  pointer-events: none; }

.only-bootstrap .btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .only-bootstrap .btn-default:focus, .only-bootstrap .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  .only-bootstrap .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .only-bootstrap .btn-default:active, .only-bootstrap .btn-default.active,
  .open > .only-bootstrap .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad; }
    .only-bootstrap .btn-default:active:hover, .only-bootstrap .btn-default:active:focus, .only-bootstrap .btn-default:active.focus, .only-bootstrap .btn-default.active:hover, .only-bootstrap .btn-default.active:focus, .only-bootstrap .btn-default.active.focus,
    .open > .only-bootstrap .btn-default.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-default.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-default.dropdown-toggle.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
  .only-bootstrap .btn-default.disabled:hover, .only-bootstrap .btn-default.disabled:focus, .only-bootstrap .btn-default.disabled.focus, .only-bootstrap .btn-default[disabled]:hover, .only-bootstrap .btn-default[disabled]:focus, .only-bootstrap .btn-default[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-default:hover,
  fieldset[disabled] .only-bootstrap .btn-default:focus,
  fieldset[disabled] .only-bootstrap .btn-default.focus {
    background-color: #fff;
    border-color: #ccc; }
  .only-bootstrap .btn-default .badge {
    color: #fff;
    background-color: #333; }

.only-bootstrap .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }
  .only-bootstrap .btn-primary:focus, .only-bootstrap .btn-primary.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40; }
  .only-bootstrap .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
  .only-bootstrap .btn-primary:active, .only-bootstrap .btn-primary.active,
  .open > .only-bootstrap .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #286090;
    background-image: none;
    border-color: #204d74; }
    .only-bootstrap .btn-primary:active:hover, .only-bootstrap .btn-primary:active:focus, .only-bootstrap .btn-primary:active.focus, .only-bootstrap .btn-primary.active:hover, .only-bootstrap .btn-primary.active:focus, .only-bootstrap .btn-primary.active.focus,
    .open > .only-bootstrap .btn-primary.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-primary.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40; }
  .only-bootstrap .btn-primary.disabled:hover, .only-bootstrap .btn-primary.disabled:focus, .only-bootstrap .btn-primary.disabled.focus, .only-bootstrap .btn-primary[disabled]:hover, .only-bootstrap .btn-primary[disabled]:focus, .only-bootstrap .btn-primary[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-primary:hover,
  fieldset[disabled] .only-bootstrap .btn-primary:focus,
  fieldset[disabled] .only-bootstrap .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4; }
  .only-bootstrap .btn-primary .badge {
    color: #337ab7;
    background-color: #fff; }

.only-bootstrap .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }
  .only-bootstrap .btn-success:focus, .only-bootstrap .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625; }
  .only-bootstrap .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  .only-bootstrap .btn-success:active, .only-bootstrap .btn-success.active,
  .open > .only-bootstrap .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    background-image: none;
    border-color: #398439; }
    .only-bootstrap .btn-success:active:hover, .only-bootstrap .btn-success:active:focus, .only-bootstrap .btn-success:active.focus, .only-bootstrap .btn-success.active:hover, .only-bootstrap .btn-success.active:focus, .only-bootstrap .btn-success.active.focus,
    .open > .only-bootstrap .btn-success.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-success.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-success.dropdown-toggle.focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625; }
  .only-bootstrap .btn-success.disabled:hover, .only-bootstrap .btn-success.disabled:focus, .only-bootstrap .btn-success.disabled.focus, .only-bootstrap .btn-success[disabled]:hover, .only-bootstrap .btn-success[disabled]:focus, .only-bootstrap .btn-success[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-success:hover,
  fieldset[disabled] .only-bootstrap .btn-success:focus,
  fieldset[disabled] .only-bootstrap .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .only-bootstrap .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }

.only-bootstrap .btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }
  .only-bootstrap .btn-info:focus, .only-bootstrap .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85; }
  .only-bootstrap .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  .only-bootstrap .btn-info:active, .only-bootstrap .btn-info.active,
  .open > .only-bootstrap .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #269abc; }
    .only-bootstrap .btn-info:active:hover, .only-bootstrap .btn-info:active:focus, .only-bootstrap .btn-info:active.focus, .only-bootstrap .btn-info.active:hover, .only-bootstrap .btn-info.active:focus, .only-bootstrap .btn-info.active.focus,
    .open > .only-bootstrap .btn-info.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-info.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-info.dropdown-toggle.focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85; }
  .only-bootstrap .btn-info.disabled:hover, .only-bootstrap .btn-info.disabled:focus, .only-bootstrap .btn-info.disabled.focus, .only-bootstrap .btn-info[disabled]:hover, .only-bootstrap .btn-info[disabled]:focus, .only-bootstrap .btn-info[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-info:hover,
  fieldset[disabled] .only-bootstrap .btn-info:focus,
  fieldset[disabled] .only-bootstrap .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da; }
  .only-bootstrap .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }

.only-bootstrap .btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }
  .only-bootstrap .btn-warning:focus, .only-bootstrap .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d; }
  .only-bootstrap .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  .only-bootstrap .btn-warning:active, .only-bootstrap .btn-warning.active,
  .open > .only-bootstrap .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    background-image: none;
    border-color: #d58512; }
    .only-bootstrap .btn-warning:active:hover, .only-bootstrap .btn-warning:active:focus, .only-bootstrap .btn-warning:active.focus, .only-bootstrap .btn-warning.active:hover, .only-bootstrap .btn-warning.active:focus, .only-bootstrap .btn-warning.active.focus,
    .open > .only-bootstrap .btn-warning.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-warning.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-warning.dropdown-toggle.focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d; }
  .only-bootstrap .btn-warning.disabled:hover, .only-bootstrap .btn-warning.disabled:focus, .only-bootstrap .btn-warning.disabled.focus, .only-bootstrap .btn-warning[disabled]:hover, .only-bootstrap .btn-warning[disabled]:focus, .only-bootstrap .btn-warning[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-warning:hover,
  fieldset[disabled] .only-bootstrap .btn-warning:focus,
  fieldset[disabled] .only-bootstrap .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236; }
  .only-bootstrap .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }

.only-bootstrap .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }
  .only-bootstrap .btn-danger:focus, .only-bootstrap .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19; }
  .only-bootstrap .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  .only-bootstrap .btn-danger:active, .only-bootstrap .btn-danger.active,
  .open > .only-bootstrap .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    background-image: none;
    border-color: #ac2925; }
    .only-bootstrap .btn-danger:active:hover, .only-bootstrap .btn-danger:active:focus, .only-bootstrap .btn-danger:active.focus, .only-bootstrap .btn-danger.active:hover, .only-bootstrap .btn-danger.active:focus, .only-bootstrap .btn-danger.active.focus,
    .open > .only-bootstrap .btn-danger.dropdown-toggle:hover,
    .open > .only-bootstrap .btn-danger.dropdown-toggle:focus,
    .open > .only-bootstrap .btn-danger.dropdown-toggle.focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19; }
  .only-bootstrap .btn-danger.disabled:hover, .only-bootstrap .btn-danger.disabled:focus, .only-bootstrap .btn-danger.disabled.focus, .only-bootstrap .btn-danger[disabled]:hover, .only-bootstrap .btn-danger[disabled]:focus, .only-bootstrap .btn-danger[disabled].focus,
  fieldset[disabled] .only-bootstrap .btn-danger:hover,
  fieldset[disabled] .only-bootstrap .btn-danger:focus,
  fieldset[disabled] .only-bootstrap .btn-danger.focus {
    background-color: #d9534f;
    border-color: #d43f3a; }
  .only-bootstrap .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }

.only-bootstrap .btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0; }
  .only-bootstrap .btn-link, .only-bootstrap .btn-link:active, .only-bootstrap .btn-link.active, .only-bootstrap .btn-link[disabled],
  fieldset[disabled] .only-bootstrap .btn-link {
    background-color: transparent;
    box-shadow: none; }
  .only-bootstrap .btn-link, .only-bootstrap .btn-link:hover, .only-bootstrap .btn-link:focus, .only-bootstrap .btn-link:active {
    border-color: transparent; }
  .only-bootstrap .btn-link:hover, .only-bootstrap .btn-link:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent; }
  .only-bootstrap .btn-link[disabled]:hover, .only-bootstrap .btn-link[disabled]:focus,
  fieldset[disabled] .only-bootstrap .btn-link:hover,
  fieldset[disabled] .only-bootstrap .btn-link:focus {
    color: #777777;
    text-decoration: none; }

.only-bootstrap .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.only-bootstrap .btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.only-bootstrap .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.only-bootstrap .btn-block {
  display: block;
  width: 100%; }

.only-bootstrap .btn-block + .btn-block {
  margin-top: 5px; }

.only-bootstrap input[type="submit"].btn-block,
.only-bootstrap input[type="reset"].btn-block,
.only-bootstrap input[type="button"].btn-block {
  width: 100%; }

.only-bootstrap .clearfix:before, .only-bootstrap .clearfix:after {
  display: table;
  content: " "; }

.only-bootstrap .clearfix:after {
  clear: both; }

.only-bootstrap .center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.only-bootstrap .pull-right {
  float: right !important; }

.only-bootstrap .pull-left {
  float: left !important; }

.only-bootstrap .hide {
  display: none !important; }

.only-bootstrap .show {
  display: block !important; }

.only-bootstrap .invisible {
  visibility: hidden; }

.only-bootstrap .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.only-bootstrap .hidden {
  display: none !important; }

.only-bootstrap .affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.only-bootstrap .visible-xs {
  display: none !important; }

.only-bootstrap .visible-sm {
  display: none !important; }

.only-bootstrap .visible-md {
  display: none !important; }

.only-bootstrap .visible-lg {
  display: none !important; }

.only-bootstrap .visible-xs-block,
.only-bootstrap .visible-xs-inline,
.only-bootstrap .visible-xs-inline-block,
.only-bootstrap .visible-sm-block,
.only-bootstrap .visible-sm-inline,
.only-bootstrap .visible-sm-inline-block,
.only-bootstrap .visible-md-block,
.only-bootstrap .visible-md-inline,
.only-bootstrap .visible-md-inline-block,
.only-bootstrap .visible-lg-block,
.only-bootstrap .visible-lg-inline,
.only-bootstrap .visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .only-bootstrap .visible-xs {
    display: block !important; }
  .only-bootstrap table.visible-xs {
    display: table !important; }
  .only-bootstrap tr.visible-xs {
    display: table-row !important; }
  .only-bootstrap th.visible-xs,
  .only-bootstrap td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .only-bootstrap .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .only-bootstrap .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .only-bootstrap .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .only-bootstrap .visible-sm {
    display: block !important; }
  .only-bootstrap table.visible-sm {
    display: table !important; }
  .only-bootstrap tr.visible-sm {
    display: table-row !important; }
  .only-bootstrap th.visible-sm,
  .only-bootstrap td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .only-bootstrap .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .only-bootstrap .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .only-bootstrap .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .only-bootstrap .visible-md {
    display: block !important; }
  .only-bootstrap table.visible-md {
    display: table !important; }
  .only-bootstrap tr.visible-md {
    display: table-row !important; }
  .only-bootstrap th.visible-md,
  .only-bootstrap td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .only-bootstrap .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .only-bootstrap .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .only-bootstrap .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .only-bootstrap .visible-lg {
    display: block !important; }
  .only-bootstrap table.visible-lg {
    display: table !important; }
  .only-bootstrap tr.visible-lg {
    display: table-row !important; }
  .only-bootstrap th.visible-lg,
  .only-bootstrap td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .only-bootstrap .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .only-bootstrap .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .only-bootstrap .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .only-bootstrap .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .only-bootstrap .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .only-bootstrap .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .only-bootstrap .hidden-lg {
    display: none !important; } }

.only-bootstrap .visible-print {
  display: none !important; }

@media print {
  .only-bootstrap .visible-print {
    display: block !important; }
  .only-bootstrap table.visible-print {
    display: table !important; }
  .only-bootstrap tr.visible-print {
    display: table-row !important; }
  .only-bootstrap th.visible-print,
  .only-bootstrap td.visible-print {
    display: table-cell !important; } }

.only-bootstrap .visible-print-block {
  display: none !important; }
  @media print {
    .only-bootstrap .visible-print-block {
      display: block !important; } }

.only-bootstrap .visible-print-inline {
  display: none !important; }
  @media print {
    .only-bootstrap .visible-print-inline {
      display: inline !important; } }

.only-bootstrap .visible-print-inline-block {
  display: none !important; }
  @media print {
    .only-bootstrap .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .only-bootstrap .hidden-print {
    display: none !important; } }

.only-bootstrap .md-text-field--inline-indicator {
  width: calc(100%); }

