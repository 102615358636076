.inputmr {
  margin-right: 10px;
}

.custom-chip {
  margin-right: 5px;
  background: whitesmoke;
}

.custom-chip:hover {
  background: gray;
}

.column-style {
  font-size: 10px;
  padding: 0.2rem !important;
  text-align: center;
}
.loading-blue .md-circular-progress-path {
  stroke: #3f51b5 !important;
}

.header-style {
  padding: 0.2rem !important;
  text-align: center;
  width: 80s;
}

.boldText {
  font-weight: bold;
}

.mousePointer {
  cursor: pointer;
}

.tableHeight {
  max-height: 300px;
}

.status-text-style {
  display: inline;
  vertical-align: super;
}

/* .fix-menu {
  position: relative !important;
}

.fix-menu ul {
  position: relative;
 
}
.fix-menu .md-list-tile {
  height: 30px !important;
} */
.dialog-fix .md-cell--2-offset,
.dialog-fix .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
  margin-left: 0px !important;
}

.dialog-fix .md-cell--8,
.dialog-fix .md-cell--8-desktop.md-cell--8-desktop {
  width: 100% !important;
}

.footertTable .md-table-pagination {
  margin-left: 660px !important;
}
