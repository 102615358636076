@import '~react-md/src/scss/react-md';

$md-light-theme: true; // optional for light theme
$md-primary-color: #0d112b; //#019ee1; //#0d112b;
$md-secondary-color: #019ee1; //$md-light-blue-a-200;

@include react-md-everything;
// Or for a subsection
@include react-md-theme-everything(
  $md-blue-grey-500,
  $md-red-a-700,
  $md-light-theme,
  'custom-theme'
);

.fa,
.fab,
.fal,
.far,
.fas {
  font-size: 21px !important;
}

.margin-top {
  margin-top: 50px;
}

.toolbar-height {
  height: 64px;
}

.custom-success-text {
  color: $md-green-600;
}

.custom-error-text {
  color: $md-red-400;
}

.MyPaginContainer {
  display: flex !important;
  justify-content: center !important;
}

.ag-grid-custom-link {
  color: black !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.ag-grid-custom-link:hover {
  color: black;
  cursor: pointer;
  text-decoration: underline;
}

.ag-grid-dropdown {
  background-color: rgb(1, 158, 225) !important;
  color: rgb(255, 255, 255);
  height: 32px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
  border-radius: 2px;
  font-weight: 500;
  padding: 8px 16px;
  text-transform: uppercase;
  border: none;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.ag-grid-dropdown > option {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 99%) !important;
}

.ag-paging-panel {
  align-items: center;
  display: flex;
  justify-content: center;
}

.ag-floating-filter-input:read-only {
  background-color: transparent !important;
}

.ag-watermark,
.ag-opacity-zero,
.ag-hidden {
  display: none;
}

.ag-theme-material
  .ag-root-wrapper-body
  .ag-checkbox-input-wrapper.ag-checked::after {
  color: #019ee1;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: #019ee1;
}

.ag-theme-material .ag-toggle-button.ag-selected .ag-input-wrapper {
  background-color: #019ee1;
  border-color: #019ee1;
}

.ag-theme-material .ag-toggle-button-input-wrapper.ag-checked::before {
  border-color: #019ee1;
}

.flatfile-component {
  z-index: 1000 !important;
}

.MuiLinearProgress-colorSecondary {
  background-color: #faa !important;
}

.MuiLinearProgress-barColorSecondary {
  background-color: #f00 !important;
}

.cell-wrap-text {
  //white-space: normal !important;
  font-size: 0.875rem !important;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start; /* align horizontal */
  align-items: center;
}

.only-bootstrap {
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

  // Core CSS
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/type';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/code';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/grid';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/tables';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/forms';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/buttons';

  // Utility classes
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/utilities';
  @import '~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

  .md-text-field--inline-indicator {
    width: calc(100%);
  }
}
