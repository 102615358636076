/*
	* Template name: Authfy - Responsive Login and Signup Page Template
	* Template URI: http://koder.top/demo/authfy/
	* Version: 1.0	
	* Author: Farhadur Rahim
	* Author URI: https://themeforest.net/user/webmechanicx
*/

/* Table of Content
==================================================
	#01. Web Fonts
	#02. Common CSS
	#03. Section Panel UI CSS
    #04. Check box UI CSS
    #05. Responsive CSS
*/

/*--------------------*/
/* Import all style */
/*--------------------*/

/* @import 'bootstrap.min.css'; */
/* @import 'font-awesome.min.css'; */
/* @import 'preloader.css'; */

/*--------------------*/
/* 01. Web Fonts */
/*--------------------*/

@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900');

/*--------------------*/
/* 02. Common CSS */
/*--------------------*/

/* body,
html {
  background-color: #f0f2f5;
  background-image: url('../images/computer-1867758_1920-min.jpg');
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

body {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  color: #44525f;
} */

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #2d385e;
}

a:focus {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Titillium Web', sans-serif;
  color: #2d385e;
}

a,
a:hover,
a:focus {
  color: #4f77ff;
}

.btn-primary {
  background-color: #4f77ff;
  border-color: #4f77ff;
}

.btn-primary:hover,
.btn-primary:active:hover,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary:active,
.btn-primary.active {
  background-color: #486ff2;
  border-color: #486ff2;
}

.authfy-login .btn.btn-lg {
  border-radius: 3px;
  box-shadow: 0px 2px 3px #9c9c9c;
}

.brand-logo {
  margin-top: 0px;
  margin-bottom: 35px;
}

/*--------------------*/
/* 03. Section Panel UI CSS */
/*--------------------*/

.authfy-container {
  margin-top: 97px;
  margin-bottom: 97px;
}

.authfy-panel-left {
  background-color: rgb(92, 156, 220, 0.92);
  /* background-color: rgb(4, 156, 220, 0.92); */
  /* background-color: rgba(38, 43, 72, 0.92); */
  color: #ffffff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-box-shadow: 0px 3px 6px #1d2030;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.authfy-panel-right {
  background-color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-box-shadow: 0px 2px 6px 0px #1d2030;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.authfy-login,
.authfy-panel-left .brand-col {
  height: 460px;
}
.authfy-panel-left .brand-col {
  display: table;
  width: 100%;
  padding: 25px;
}

.authfy-panel-left .brand-col .headline {
  display: table-cell;
  vertical-align: middle;
}

.authfy-panel-left .brand-col .headline h1,
.authfy-panel-left .brand-col .headline h2,
.authfy-panel-left .brand-col .headline h3 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

.authfy-login {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.authfy-panel {
  padding: 20px 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.authfy-panel .authfy-heading {
  margin-bottom: 30px;
}

.authfy-login .auth-title {
  font-weight: 700;
}

.authfy-login .authfy-panel.active {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
}

.brand-col .social-buttons a {
  color: #ffffff;
  margin-top: 15px;
}

.brand-col .social-buttons a:hover {
  color: white;
  opacity: 0.9;
}

.brand-col .social-buttons .btn-facebook {
  background: #3b5998;
}
.brand-col .social-buttons .btn-twitter {
  background: #00aced;
}
.brand-col .social-buttons .btn-google {
  background: #c32f10;
}

.brand-col .social-buttons a > span {
  padding-left: 5px;
}

.panel-login {
  margin-top: 20px;
}

.panel-login .remember-row {
  margin-bottom: 10px;
}

.panel-login .remember-row label {
  font-weight: normal;
  position: relative;
  cursor: pointer;
  color: #666;
  padding-left: 24px;
}

.authfy-login .forgotPwd {
  text-align: right;
  margin-top: 10px;
}

.panel-login .form-control,
.panel-signup .form-control,
.panel-forgot .form-control {
  color: #333333;
  font-size: 16px;
  height: 50px;
  padding: 12px 0px;
  outline: none;
  border: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.panel-login .form-control:focus + .focus-input:before,
.panel-signup .form-control:focus + .focus-input:before,
.panel-forgot .form-control:focus + .focus-input:before {
  width: 100%;
}

.wrap-input {
  width: 100%;
  position: relative;
}

.focus-input {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input:before {
  background-color: #4f77ff;
  content: '';
  width: 0;
  height: 2px;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.authfy-panel.panel-forgot {
  padding-top: 40px;
}

.pwdMask {
  position: relative;
}

.pwdMask .pwd-toggle {
  color: #cccccc;
  position: absolute;
  z-index: 2;
}

.panel-login .pwdMask .pwd-toggle,
.panel-signup .pwdMask .pwd-toggle {
  top: 15px;
  right: 15px;
}

.pwdMask .pwd-toggle:hover,
.pwdMask .pwd-toggle:focus {
  cursor: pointer;
}

.pwdMask .pwd-toggle:hover,
.pwdMask .pwd-toggle:focus,
.pwdMask .pwd-toggle.fa-eye {
  color: #4f77ff;
}

.panel-signup .term-policy a {
  text-decoration: underline;
}

/*--------------------*/
/* 04. Check box UI CSS */
/*--------------------*/

.checkbox input[type='checkbox'] {
  position: absolute;
  right: 9000px;
}

.checkbox input[type='checkbox'] + .label-text:before,
.checkbox input[type='checkbox'] + .label-text:after {
  font-size: 11px;
  display: inline-block;
  width: 17px;
  height: 17px;
  padding: 2px;
  margin-left: 0;
  position: absolute;
  top: 2px;
  left: 0;
}

.checkbox input[type='checkbox'] + .label-text:before {
  content: '';
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox input[type='checkbox'] + .label-text:after {
  background-color: #4f77ff;
  color: #ffffff;
  border-radius: 2px;
  border-color: #4f77ff;
  line-height: 1.4;
}

.checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.checkbox input[type='checkbox']:focus + .label-text:before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox input[type='checkbox']:checked + .label-text:after {
  font-family: 'FontAwesome';
  content: '\f00c';
}

/*--------------------*/
/* 05. Responsive CSS */
/*--------------------*/

@media (min-width: 768px) {
  .authfy-panel {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .authfy-panel-left,
  .authfy-panel-right {
    box-shadow: none;
  }

  .authfy-panel-left {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .authfy-panel-right {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .authfy-panel-left .brand-col {
    height: 300px;
    text-align: center;
  }

  .term-policy {
    font-size: 75%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .authfy-login .authfy-panel {
    padding: 20px;
  }

  .panel-login .remember-row {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .authfy-login {
    height: 480px;
  }
  .authfy-heading p {
    font-size: 14px;
  }

  .remember-row .col-sm-6 {
    width: 100%;
  }

  .authfy-login .forgotPwd {
    text-align: left;
    margin-top: 0px;
  }
}
